import React, { Component } from "react";
import classNames from "classnames";
import { AppTopbar } from "./AppTopbar";
import { AppBreadcrumb } from "./AppBreadcrumb";
import { AppFooter } from "./AppFooter";
import { Route } from "react-router-dom";
//import { useCookies } from "react-cookie";
import cookie from "react-cookies";

//import { Dashboard } from "./components/Dashboard";
// import { FormsDemo } from "./components/FormsDemo";
// import { SampleDemo } from "./components/SampleDemo";
// import { DataDemo } from "./components/DataDemo";
// import { PanelsDemo } from "./components/PanelsDemo";
// import { OverlaysDemo } from "./components/OverlaysDemo";
// import { MenusDemo } from "./components/MenusDemo";
// import { MessagesDemo } from "./components/MessagesDemo";
// import { ChartsDemo } from "./components/ChartsDemo";
// import { MiscDemo } from "./components/MiscDemo";
import { EmptyPage } from "./components/EmptyPage";
// import { UtilsDemo } from "./components/UtilsDemo";
// import { Documentation } from "./components/Documentation";

import { UserList } from "./main/userlist";
import { UserConeect } from "./main/userconeect";

import { UserDetail } from "./main/userdetail";
import { Dashboard } from "./main/dashboard";

import { AgentNote } from "./main/agentnote";


import { DeporsitAndWithdraw } from "./main/deporsitandwithdraw";
import { DeporsitAndWithdrawTotal } from "./main/deporsitandwithdrawtotal";


// import { DetailMatch } from "./main/detailmatch";
// import { DetailMini } from "./main/detailmini";

//C:\WORK\eBetting\admin\src\main\progressLive.js

//C:\WORK\eBetting\admin\src\main\betLive.js
import { BetLive } from "./main/betlive";
import { BetCasino } from "./main/betcasino";
import { BetSlot } from "./main/betslot";
import { BetSlotOrg } from "./main/betslotorg";

import { BetMini } from "./main/betmini";
import { BetMatch } from "./main/betmatch";
import { BetFX } from "./main/betfx";

import { Balance } from "./main/balance";
import { BalanceGiveUser } from "./main/balancegiveuser";
import { BalanceLog } from "./main/balancelog";

import { Agent } from "./main/agent";
import { AgentCasino } from "./main/agentCasino";

import { AgentBalanceList } from "./main/agentbalancelist";
import { AgentBalanceLog } from "./main/agentbalancelog";
import { AgentDetail } from "./main/agentdetail";

import { CalculateAgent } from "./main/calculateagent";
import { CalculateSubAgent } from "./main/calculatesubagent";
import { CalculateSubAgentSingle } from "./main/calculatesubagentsingle";
import { CalculateSubAgentBalance } from "./main/calculatesubagentbalance";

import { NoticeAgent } from "./main/noticeagent";
import { PopupNotice } from "./main/popupnotice";

import { AgentHelp } from "./main/agenthelp";
import { AgentPW } from "./main/agentpw";



import { withRouter, matchPath } from "react-router";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "./ripple.js";
import "./App.css";
import { AppConfig } from "./AppConfig";
// import { MatchDetail } from "./share/progress/match-detail";
import { AgentService } from "./service/agent.service";
import { AxiosMag } from "./service/axios.service";

import { throws } from "assert";

import SoundPlays from "./soundplay";


interface Props {}
interface State {
  horizontal: boolean;
  topbarSize: string;
  topbarColor: string;
  menuColor: string;
  layoutColor: string;
  themeColor: string;
  menuActive: boolean;
  configDialogActive: boolean;
  topbarUserMenuActive: boolean;
  admin: any;
  management: any;
}


// name: cookies.get('session') || 'Ben'

class App extends Component<Props, State> {

  interval : any = null

  layoutContainer: any;
  agentService: any = new AgentService();

  constructor(props: any) {
    super(props);

    this.state = {
      admin: {},
      management: {},
      horizontal: true,
      topbarSize: "large",
      topbarColor: "layout-topbar-blue",
      menuColor: "layout-menu-light",
      layoutColor: "blue",
      themeColor: "blue",
      menuActive: false,
      configDialogActive: false,
      topbarUserMenuActive: false,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarUserMenuButtonClick = this.onTopbarUserMenuButtonClick.bind(
      this
    );
    this.onTopbarUserMenuClick = this.onTopbarUserMenuClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.changeTopbarTheme = this.changeTopbarTheme.bind(this);
    this.changeTopbarSize = this.changeTopbarSize.bind(this);
    this.changeMenuToHorizontal = this.changeMenuToHorizontal.bind(this);
    this.changeMenuTheme = this.changeMenuTheme.bind(this);
    this.changeComponentTheme = this.changeComponentTheme.bind(this);
    this.changePrimaryColor = this.changePrimaryColor.bind(this);
    this.onToggleBlockBodyScroll = this.onToggleBlockBodyScroll.bind(this);
    this.isHorizontalMenuActive = this.isHorizontalMenuActive.bind(this);

    if(this.interval == null){
      this.interval = setInterval(() => {
        this.update();
      }, 10000);
    }
  }


  update = () =>{
    try{

      if(location.hash.indexOf('agentDetail') >= 0 || location.hash.indexOf('userDetail') >= 0){
        return
      }


      let session = cookie.load("session");
      AxiosMag.SetSession(session);


      this.agentService.agent_check_health(session).then((data : any) => {
        console.log(data);
        if (data.status == "success") {

          this.setState({admin: data.admin});

          if(data.helpCount >= 1 || data.noteCount >= 1 ){
            SoundPlays('agentHelpCount')
          }


        } else {
          cookie.remove("admin", { path: "/" });

          window.location.hash = '/'
          this.setState({
            admin: null,
          });
        }
      });

    }
    catch
    {}

   }

  menuClick = false;

  configMenuClick = false;

  userMenuClick = false;

  menu: any[] = [];

  onWrapperClick(event: any) {
    if (!this.menuClick) {
      this.setState({ menuActive: false });

      if (!this.configMenuClick) {
        this.unblockBodyScroll();
      }
    }

    if (!this.userMenuClick) {
      this.setState({ topbarUserMenuActive: false });
    }

    this.userMenuClick = false;
    this.menuClick = false;
    this.configMenuClick = false;
  }

  onMenuButtonClick(event: any, isMenuButtonActive: any) {
    this.menuClick = true;

    if (!this.isHorizontalMenuActive()) {
      this.setState({ menuActive: !isMenuButtonActive }, () => {
        if (this.state.menuActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      });
    }

    event.preventDefault();
  }

  onToggleBlockBodyScroll(add: boolean) {
    if (add) this.blockBodyScroll();
    else this.unblockBodyScroll();

    this.configMenuClick = true;
  }

  blockBodyScroll() {
    let className = `blocked-scroll${
      this.state.horizontal ? "-horizontal-menu" : ""
    }`;
    if (document.body.classList) {
      document.body.classList.add(className);
    } else {
      document.body.className += ` ${className}`;
    }
  }

  unblockBodyScroll() {
    let className = `blocked-scroll${
      this.state.horizontal ? "-horizontal-menu" : ""
    }`;
    if (document.body.classList) {
      document.body.classList.remove(className);
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + `${className}`.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  onTopbarUserMenuButtonClick(event: any) {
    this.userMenuClick = true;
    this.setState({ topbarUserMenuActive: !this.state.topbarUserMenuActive });

    event.preventDefault();
  }

  onTopbarUserMenuClick(event: any) {
    this.userMenuClick = true;

    if (
      event.target.nodeName === "BUTTON" ||
      event.target.parentNode.nodeName === "BUTTON"
    ) {
      this.setState({ topbarUserMenuActive: false });
    }
    event.preventDefault();
  }

  onRootMenuItemClick(event: any) {
    this.menuClick = true;

    if (this.isHorizontalMenuActive()) {
      this.setState({
        menuActive: !this.state.menuActive,
      });
    }
  }

  onMenuItemClick(event: any) {
    if (!event.item.items) {
      this.setState({ menuActive: false });
    }
  }

  onSidebarClick = (event: any) => {
    this.menuClick = true;
  };

  isMobile() {
    return window.innerWidth <= 1024;
  }

  isHorizontalMenuActive() {
    return this.state.horizontal && !this.isMobile();
  }

  changeTopbarSize(event: any) {
    this.setState({ topbarSize: event.size });

    event.originalEvent.preventDefault();
  }

  changeTopbarTheme(event: any) {
    this.setState({ topbarColor: "layout-topbar-" + event.color });

    event.originalEvent.preventDefault();
  }

  changeMenuToHorizontal(event: any) {
    this.setState({ horizontal: event.mode });

    event.originalEvent.preventDefault();
  }

  changeMenuTheme(event: any) {
    this.setState({ menuColor: "layout-menu-" + event.color });

    event.originalEvent.preventDefault();
  }

  changeComponentTheme(event: any) {
    this.setState({ themeColor: event.theme });

    let element = document.getElementById("theme-css");
    if (element != null) {
      let urlTokens = element!.getAttribute("href")!.split("/");
      urlTokens[urlTokens.length - 1] = "theme-" + event.theme + ".css";
      let newURL = urlTokens.join("/");
      element.setAttribute("href", newURL);
    }

    event.originalEvent.preventDefault();
  }

  changePrimaryColor(event: any) {
    this.setState({ layoutColor: event.color });

    let element = document.getElementById("layout-css");
    if (element != null) {
      let urlTokens = element!.getAttribute("href")!.split("/");
      urlTokens[urlTokens.length - 1] = "layout-" + event.color + ".css";
      let newURL = urlTokens.join("/");
      element.setAttribute("href", newURL);
    }

    event.originalEvent.preventDefault();
  }

  componentDidMount = () => {
    console.log("componentDidMount");

    // const [cookies, setCookie] = useCookies(["session"]);
    // session: string;
    let session = cookie.load("session");
    AxiosMag.SetSession(session);

    // this.agentService.admin_get_management().then((s) => {
    //   this.setState({ management: s.management });
    // });


  };

  createMenu() {
    this.menu = [
      { label: "Dashboard", icon: "dashboard", to: "/dashboard" },
      { label: "유저 관리", icon: "list", to: "userlist",},
      { label: "현재 접속자", icon: "list", to: "userconeect",},
      { label: "충/환전내역", icon: "list", to: "deporsitAndwithdraw",},

      { label: "에이전트 충/환전/알", icon: "list", to: "agentBalanceList",},
      { label: "매장 벨런스 내역", icon: "list", to: "balancegiveuser",},

      {
        label:  "슬롯 내역",
        icon: "list",
        to: "betslot",

      },
      {
        label:  "카지노 내역",
        icon: "list",
        to: "betcasino",

      },
      // {
      //   label: "일/월별 통계",
      //   icon: "list",
      //   to: "calculateagent",
      // },

      {
        label: "일/월별 통계",
        icon: "list",
        to: "calculatesubagent",
      },

      {
        label: "알신청/수수료전환",
        icon: "list",
        to: "balance",

      },

      {
        label: "파트너 관리",
        icon: "list",
        to: "agent",
      },
      {
        label: "문의",
        icon: "list",
        to: "agenthelp",
      },
      {
        label: "에이전트공지",
        icon: "list",
        to: "noticeagent",
      },
      {
        label: "쪽지함",
        icon: "list",
        to: "agentnote",
      },


      // {
      //   label: "커뮤니티",
      //   icon: "list",
      //   items: [
      //     { label: "에이전트공지", icon: "desktop_mac", to: "/noticeagent" },
      //   ],
      // },
    ];



    // for (let items of this.menu) {
    //   if (items.items != null) {
    //     let submenu: any = [];
    //     for (let item of Object.values(items.items) as any) {
    //       if (management.sportpre === "y" && item.name === "sportpre") {
    //         submenu.push(item);
    //       } else if (
    //         management.sportlive === "y" &&
    //         item.name === "sportlive"
    //       ) {
    //         submenu.push(item);
    //       } else if (management.mini === "y" && item.name === "mini") {
    //         submenu.push(item);
    //       } else if (management.casino === "y" && item.name === "casino") {
    //         submenu.push(item);
    //       } else if (management.slot === "y" && item.name === "slot") {
    //         submenu.push(item);
    //       } else if (management.fx === "y" && item.name === "fx") {
    //         submenu.push(item);
    //       } else if (item.name == null) {
    //         submenu.push(item);
    //       }
    //     }

    //     if (submenu.length > 0) {
    //       newmenu.push({
    //         label: items.label,
    //         icon: items.icon,
    //         items: submenu,
    //       });
    //     }
    //   }
    // }

  }

  render() {
    this.createMenu();

    if (this.state.admin == null) {
      window.location.href = "/";
    }

    const layoutContainerClassName = classNames(
      "layout-container",
      {
        "layout-menu-horizontal": this.state.horizontal,
        "layout-menu-active":
          this.state.menuActive && !this.isHorizontalMenuActive(),
        "layout-top-small": this.state.topbarSize === "small",
        "layout-top-medium": this.state.topbarSize === "medium",
        "layout-top-large": this.state.topbarSize === "large",
      },
      this.state.topbarColor,
      this.state.menuColor
    );

    const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

    let hash = window.location.hash;

    // const PostPage = (match: any) => {
    //   return <div>blablabla</div>;
    // };
    if (hash.indexOf("userDetail") >= 0) {
      if(process.env.REACT_APP_DELETE_GIVE_BALANCE === 'y' ){
        return (<></>)
      }

      return (
        <div>
          <div className="layout-top">
            <AppBreadCrumbWithRouter />
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/userDetail/:agentID"
              component={(match: any) => {
                return (
                  <UserDetail
                    userID={match.match.params.agentID}
                  ></UserDetail>
                );
              }}
            />
          </div>

        </div>
      );
    }
    else if (hash.indexOf("agentDetail") >= 0) {
      return (
        <div>
          <div className="layout-top">
            <AppBreadCrumbWithRouter />
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/agentDetail/:agentID"
              component={(match: any) => {
                console.log("match"); // 1
                console.log(match); // 1
                return (
                  <AgentDetail
                    agentId={match.match.params.agentID}
                  ></AgentDetail>
                );
              }}
            />
          </div>

        </div>

      );
    }
    return (
      <div
        // ref={el => (this.layoutContainer = el)}
        className={layoutContainerClassName}
        onClick={this.onWrapperClick}
      >
        <div className="layout-top">
          <AppTopbar
            topbarUserMenuActive={this.state.topbarUserMenuActive}
            menuActive={this.state.menuActive}
            onMenuButtonClick={this.onMenuButtonClick}
            onTopbarUserMenuButtonClick={this.onTopbarUserMenuButtonClick}
            onTopbarUserMenuClick={this.onTopbarUserMenuClick}
            model={this.menu}
            horizontal={this.state.horizontal}
            onSidebarClick={this.onSidebarClick}
            onRootMenuItemClick={this.onRootMenuItemClick}
            onMenuItemClick={this.onMenuItemClick}
            isHorizontalMenuActive={this.isHorizontalMenuActive}
            onRootItemClick={() => {}}
          />

          <div className="layout-topbar-separator" />

          <AppBreadCrumbWithRouter />
        </div>

        <div className="layout-content">

          <Route path="/dashboard" component={Dashboard} />
          <Route path="/userlist" component={UserList} />
          <Route path="/userconeect" component={UserConeect} />

          {/* <Route path="/deporsitAndwithdraw" component={DeporsitAndWithdraw} /> */}
          <Route path="/deporsitAndwithdraw" component={DeporsitAndWithdrawTotal} />


          <Route path="/betmatch" component={BetMatch} />
          <Route path="/betlive" component={BetLive} />
          <Route path="/betcasino" component={BetCasino} />

          {

            process.env.REACT_APP_API_ORI_BET === 'STY' ? (
              <Route path="/betslot" component={BetSlotOrg} />
            ) : (
              <Route path="/betslot" component={BetSlot} />
            )
          }

          <Route path="/betmini" component={BetMini} />
          <Route path="/betfx" component={BetFX} />

          <Route path="/balance" component={Balance} />
          <Route path="/balanceLog" component={BalanceLog} />

          {
            process.env.REACT_APP_API_MODE === 'CASINO' ?
            <Route path="/agent" component={AgentCasino} /> :
            <Route path="/agent" component={Agent} />
          }


          <Route path="/agentBalanceList" component={AgentBalanceList} />
          <Route path="/agentbalancelog" component={AgentBalanceLog} />
          <Route path="/agenthelp" component={AgentHelp} />
          <Route path="/agentpw" component={AgentPW} />

          <Route path="/agentnote" component={AgentNote} />


          {
            process.env.REACT_APP_DELETE_GIVE_BALANCE === 'y' ?
            <></>            :
            <Route path="/balancegiveuser" component={BalanceGiveUser} />
          }



          <Route path="/calculateagent" component={CalculateAgent} /> :
          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'STY' ?
            <Route path="/calculatesubagent" component={CalculateSubAgentSingle} /> : (
              process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'BALANCE' ?
              <Route path="/calculatesubagent" component={CalculateSubAgentBalance} /> :
              <Route path="/calculatesubagent" component={CalculateSubAgent} />
            )
          }

          <Route path="/noticeagent" component={NoticeAgent} />
        </div>
        {process.env.REACT_APP_API_NOTICE !== 'N' && <PopupNotice></PopupNotice>}

        <AppConfig
          topbarColor={this.state.topbarColor}
          horizontal={this.state.horizontal}
          layoutColor={this.state.layoutColor}
          menuColor={this.state.menuColor}
          themeColor={this.state.themeColor}
          topbarSize={this.state.topbarSize}
          changeTopbarTheme={this.changeTopbarTheme}
          changeMenuToHorizontal={this.changeMenuToHorizontal}
          changeMenuTheme={this.changeMenuTheme}
          changeComponentTheme={this.changeComponentTheme}
          changePrimaryColor={this.changePrimaryColor}
          changeTopbarSize={this.changeTopbarSize}
          onToggleBlockBodyScroll={this.onToggleBlockBodyScroll}
        />

        {!this.isHorizontalMenuActive() && this.state.menuActive && (
          <div className="layout-mask" />
        )}

        <AppFooter />
        <div id="agentHelpCount" style={{display: 'none'}}>0</div>

      </div>
    );
  }
}

export default App;
