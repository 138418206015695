import React, { Component, Children } from "react";

import { PanelMenu } from "primereact/panelmenu";
import { TieredMenu } from "primereact/tieredmenu";
import { Menu } from "primereact/menu";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { Password } from "primereact/password";
import { InputMask } from "primereact/inputmask";
import { RadioButton } from "primereact/radiobutton";
import { Slider } from "primereact/slider";
import { MultiSelect } from "primereact/multiselect";
import { ListBox } from "primereact/listbox";

import { confirmAlert } from "react-confirm-alert";
import { TreeTable } from "primereact/treetable";

import { AlertYesNo } from "../share/alert-yes-no";
import { AgentService } from "../service/agent.service";
import { converAgentStatus, numTomoeny, convertAgentBenefitBalanceText } from "../utility/help";

interface NewAgent {
  parentId: string;
  agent: string;
  nick: string;
  pass: string;
  phone: string;
  bankname: string;
  banknum: string;
  bankowner: string;

  items: NewAgent[];
}

interface State {
  agent: any;
  balance: any;
  benefits : any;
}

export class Balance extends Component<any, State> {
  agentService: AgentService = new AgentService();
  constructor(props: any) {
    super(props);

    this.state = {
      agent: {},
      balance: { point: 0, rolling: 0, losing: 0, balance: 0 , chBalance: 0},
      benefits : []
    };



    this.handleGetAgentBalance();
    this.handleGetAgentBenefit();
  }

  componentDidMount() {

  }

  handleGetAgentBalance = () => {
    this.agentService.agent_get_agent_info().then(s => {
      if (s.status == "success") {
        this.setState({ agent: s.agent });
      }
    });
  };

  
  handleGetAgentBenefit = () => {
    this.agentService.agent_get_benefits().then(s => {
      if (s.status == "success") {
        this.setState({ benefits: s.benefits });
      }
    });
  };

  

  handleChangeBalance = (balance : any, ex: any) => {
    // if (balance % 1000 != 0) {
    //   confirmAlert({
    //     title: "에이전트 벨런스",
    //     message: "충전할 알은 1,000 단위 로 입력해주세요.",
    //     buttons: [
    //       {
    //         label: "확인",
    //         onClick: () => {}
    //       }
    //     ]
    //   });
    //   return;
    // }

    if (balance.type != "balance") {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "에이전트 알을 충전신청합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {


              this.agentService.agent_change_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
              });
            }
          }
        ]
      });
    } 
  };

  
  handleExchangeBalance = (balance, ex) => {
    if (balance % 1000 != 0) {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "변환할 금액을 1,000 단위 로 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {}
          }
        ]
      });
      return;
    }

    if (balance.type != "balance") {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "에이전트 루징/포인트/롤링을 벨런스로 변환 신청을 합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {

              if(process.env.REACT_APP_API_ROOLING_EXCHANGE_TO_TOP_AGENT_BALANCE === 'Y' ){
                this.agentService.agent_exchange_balance_top_balance(ex).then(s => {
                  if (s.status == "success") {
                    window.location.reload();
                  }
                });
  
              }
              else{
                this.agentService.agent_exchange_balance(ex).then(s => {
                  if (s.status == "success") {
                    window.location.reload();
                  }
                });
  
              }
              
            }
          }
        ]
      });
    } else {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "벨런스를 환전 신청합니다 잠시만 대기해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_exchange_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
              });
            }
          }
        ]
      });
    }
  };


  render() {
    let agent = this.state.agent;
    let balance = this.state.balance;

    return (
      <div>
        <div className="content">
          <div className="p-grid">

          <div className="p-md-12 p-col-12 ">
              <div className="card">
                <div className="card-title">알 충전신청</div>

                  <div className="p-md-12">
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-1">
                        <label htmlFor="input">
                          {numTomoeny(Number(agent.balance))}
                        </label>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <InputText
                          id="input"
                          value={balance.chBalance}
                          onChange={(even: any) => {
                            balance.chBalance = Number(even.target.value);

                            this.setState({ balance: balance });
                          }}
                        />

                        
                      <div className="p-grid form-group" style={{ padding: '20px' }} >
                        <Button className="p-button-sm p-button-outlined" label="10,000" onClick={() => { 
                          
                          balance.chBalance = balance.chBalance  +  Number(10000);
                          this.setState({ balance: balance });
                        }} />
                        <Button className="p-button-sm p-button-outlined" label="50,000" onClick={() => { 
                          balance.chBalance = balance.chBalance  +  Number(50000);
                          this.setState({ balance: balance });
                         }} />
                        <Button className="p-button-sm p-button-outlined" label="100,000" onClick={() => {
                          balance.chBalance = balance.chBalance  +  Number(100000);
                          this.setState({ balance: balance });
                        }} />
                        <Button className="p-button-sm p-button-outlined" label="500,000" onClick={() => { 
                          balance.chBalance = balance.chBalance  +  Number(500000);
                          this.setState({ balance: balance });
                         }} />
                        <Button className="p-button-sm p-button-outlined" label="1,000,000" onClick={() => {
                          balance.chBalance = balance.chBalance  +  Number(1000000);
                          this.setState({ balance: balance });
                        }} />
                        <Button className="p-button-sm p-button-danger" label="정정" onClick={() => { 
                          balance.chBalance = 0
                          this.setState({ balance: balance });
                         }} />
                      </div>

                      </div>

                      <div className="p-col-12 p-md-5">
                        <Button
                          label="확인"
                          className="p-button-warning"
                          onClick={(even: any) => {
                            balance.type = "balance";

                            this.handleChangeBalance(balance.chBalance, balance);
                          }}
                        />
                      </div>

                    </div>
                  </div>

              </div>
            </div>
            
            {process.env.REACT_APP_API_BANCE_EXCHANGE !== 'N' &&
            <div className="p-md-4 p-col-12 ">
              <div className="card">
                <div className="card-title">보유중 루징</div>

                  <div className="p-md-12">
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-1"  style={{marginRight : '30px'}}>
                        <label htmlFor="input">
                          {numTomoeny(Number(agent.losing))}
                        </label>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <InputText
                          id="input"
                          value={balance.losing}
                          onChange={(even: any) => {
                            balance.losing = even.target.value;

                            this.setState({ balance: balance });
                          }}
                        />
                      </div>
                      <div className="p-col-12 p-md-5">
                        <Button
                        label="루징  => 벨런스"
                        className="p-button-warning"
                          onClick={(even: any) => {
                            balance.type = "losing";

                            this.handleExchangeBalance(balance.losing, balance);
                          }}
                        />
                      </div>
                    </div>
                </div>
              </div>
            </div>}

            


            <div className="p-md-4 p-col-12 ">
              <div className="card">
                <div className="card-title">보유중 롤링</div>

                  <div className="p-md-12">
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-1"  style={{marginRight : '30px'}}>
                        <label htmlFor="input">
                          {numTomoeny(Number(agent.rolling))}
                        </label>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <InputText
                          id="input"
                          value={balance.rolling}
                          onChange={(even: any) => {
                            balance.rolling = even.target.value;

                            this.setState({ balance: balance });
                          }}
                        />
                      </div>
                      <div className="p-col-12 p-md-5">
                        <Button
                        label="롤링 => 벨런스"
                        className="p-button-warning"
                          onClick={(even: any) => {
                            balance.type = "rolling";

                            this.handleExchangeBalance(balance.rolling, balance);
                          }}
                        />
                      </div>

                    </div>
                  </div>

              </div>
            </div> 


            <div className="p-md-4 p-col-12 ">
              <div className="card">
                <div className="card-title">보유중 벨런스(환전금액)</div>

                  <div className="p-md-12">
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-1" style={{marginRight : '30px'}}>
                        <label htmlFor="input">
                          {numTomoeny(Number(agent.balance))}
                        </label>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <InputText
                          id="input"
                          value={balance.balance}
                          onChange={(even: any) => {
                            balance.balance = even.target.value;

                            this.setState({ balance: balance });
                          }}
                        />
                      </div>
                      <div className="p-col-12 p-md-5">
                        <Button
                        label="환전 하기"
                        className="p-button-warning"
                          onClick={(even: any) => {
                            balance.type = "balance";
                            this.handleExchangeBalance(balance.balance, balance);
                          }}
                        />
                      </div>

                    </div>
                  </div>

              </div>
            </div> 

            
            

       
            <div className="p-md-12 p-col-12 ">
                <div className="card card-w-title">
                  <div className="card-title">에이전트 벨런스</div>
                  {
                    Object.values(this.state.benefits).map((benefit : any) => {
                      return (
                        <div className="p-grid">
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="input">{convertAgentBenefitBalanceText(benefit.benefit_type)}</label>
                        </div>
                        <div className="p-col-12 p-md-4">
                                            <label htmlFor="input">
                                            {benefit.percent}
                                            </label>
                                            </div>
                    
                        </div>

                      )
                    })
                  }
                </div>
              </div>
            </div>


        </div>
      </div>
    );
  }
}
