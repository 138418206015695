import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import cookie from "react-cookies";
import { confirmAlert } from 'react-confirm-alert' // Import

import { UserService } from "../service/user.service";
import { AxiosMag } from "../service/axios.service";

interface State {
  id: string;
  pw: string;
}
export default class Login extends Component<any, State> {
  userService = new UserService();
  constructor(props: any) {
    super(props);
    this.state = {
      id: "",
      pw: ""
    };
  }

  render() {
    // const [cookies, setCookie] = useCookies(["session"]);
    // this.state = { userId: cookie.load("userId") };

    const DoLogin = () => {
      console.log(this.state.id, this.state.pw);
      this.userService
        .do_login_agent(this.state.id, this.state.pw)
        .then((data : any) => {
          if (data.status == "success") {
            cookie.save("session", data.session, { path: "/" });

            //setCookie("session", data.session, { path: "/" });

            AxiosMag.SetSession(data.session);
            window.location.href = "/#/dashboard";
            // this.setState({ user: data.user });
          } else if (data.status == "black") {

            confirmAlert({
              title: "에이전트",
              message: "해당 아이디는 로그인할수 없습니다. 관리자에게 문의 부탁드림니다.",
              buttons: [
                {
                  label: "확인",
                  onClick: () => {
                  },
                },
              ],
            });
          } else {
            window.location.reload();
          }
        });
    };

    return (
      <div className="login-body">
        <div className="login-panel ui-fluid">
          <div className="login-panel-header">
            <img
              src="assets/layout/images/login/logo-sapphire-color.png"
              alt="sapphire"
            />
          </div>
          <div className="login-panel-content">
            <div className="p-grid">
              <div className="p-col-12">
                <h1>SAPPHIRE NETWORK</h1>
                <h2>Welcome, please use the form to sign-in</h2>
              </div>
              <div className="p-col-12">
                <span className="md-inputfield">
                  <InputText
                    style={{ width: "100%" }}
                    value={this.state.id}
                    name="id"
                    
                    onChange={(event: any) => {
                      this.setState({
                        id: event.target.value
                      });
                    }}
                  />
                  <label>아이디</label>
                </span>
              </div>
              <div className="p-col-12">
                <span className="md-inputfield">
                  <InputText
                    type="password"
                    name="pw"
                    style={{ width: "100%" }}
                    value={this.state.pw}
                    onChange={(event: any) => {
                      this.setState({
                        pw: event.target.value
                      });
                    }}
                  />
                  <label>비밀번호</label>
                </span>
              </div>
              <div className="p-col-6">
                {/* <button className="p-link">Forget Password?</button> */}
              </div> 
              <div className="p-col-6" style={{ textAlign: "right" }}>
                <Button
                  label="로그인"
                  onClick={() => {
                    DoLogin();
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            해킹 방지를 위하여 관리자 아이디/비번이 연속해서 8번 틀리면 자동으로 관리자 사이트 락이 걸립니다.(로그인 불가)
            <br/> 
            아이디나 비번이 생각나지 않으시면 센터로 연락 주세요.
          </div>
        </div>
      </div>
    );
  }
}
