import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import {
  numTomoeny,
  GetToday,
  convertDate,
  converBalanceStatus,
  convertLogText,
  getDepositColor,
  getWithdrawColor,
  convertBalanceType,
} from "../../utility/help";

interface BalanceProps {
  logs: any;
}

export class LogAgentBalanceTable extends Component<any, BalanceProps> {
  constructor(props: BalanceProps) {
    super(props);
  }

  render() {
    let logs = this.props.logs;
    return (
      <DataTable
        value={logs}
        paginatorPosition="both"
        rows={20}
        sortOrder={-1}
        scrollable={true}
      >
        <Column
          style={{ textAlign: "center", width: "100px" }}
          header="총판"
          body={(rowData: any, column: any) => {
            return rowData.agent;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "100px" }}
          header="아이디"
          body={(rowData: any, column: any) => {
            return rowData.id;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "100px" }}
          header="벨런스 +-"
          body={(rowData: any, column: any) => {
            return (
              <div style={{ color: getWithdrawColor() }}>
                {numTomoeny(rowData.balance)}
              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center", width: "100px" }}
          header="남은 벨런스"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.afterbalance)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "100px" }}
          header={"에이전트"}
          body={(rowData: any, column: any) => {
            return <div>{rowData.agent}</div>;
          }}
        />
        <Column
          style={{ textAlign: "center", width: "100px" }}
          header={"타입"}
          body={(rowData: any, column: any) => {
            return <div><span dangerouslySetInnerHTML={{ __html: convertBalanceType(rowData.agent, rowData.id, rowData.giveId, rowData.targetId, rowData.logType) }} ></span>  {rowData.simplememo}</div>
          }}
        />

        <Column
          style={{ textAlign: "center", width: "180px" }}
          header={"요청시간"}
          body={(rowData: any, column: any) => {
            return <div>{convertDate(rowData.regdate)}</div>;
          }}
          field="depositdate"
        />
        {/* 
        <Column
          style={{ textAlign: "center" }}
          header={"CODE"}
          body={(rowData, column) => {
            return <div>{rowData._id}</div>;
          }}
          field="depositdate"
        /> */}
      </DataTable>
    );
  }
}
