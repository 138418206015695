import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar,  } from "primereact/calendar";
import { AgentService } from "../service/agent.service";
// import { AdminService } from "../service/admin.service";
import { SelectButton } from "primereact/selectbutton";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, convertDateYear, pad2  } from "../utility/help";

interface BalanceState {
  details: any;
  findText: any;
  searchText: any;
  select: any;
  selectItem: any;

  options: any[];
  options_type: string;
  begin: any;
  end: any;
  agents: any;
  agent_name: any;
  management: any;
  agents_option: any;
}

export class CalculateAgent extends Component<any, BalanceState> {
  agentService: AgentService;
  constructor(props: any) {
    super(props);


    const today = new Date();
    
    const begin = new Date(`${today.getFullYear()}-${pad2(today.getMonth() + 1)}-01`);

    today.setMonth(today.getMonth() + 1);

    const end = new Date(`${today.getFullYear()}-${pad2(today.getMonth() + 1)}-01`);


    this.state = {
      begin: begin,
      end: end,
      findText: "",
      searchText: "",
      selectItem: {},
      management: {},
      details: [],
      agents: [],
      agents_option: [],

      options: [{ label: "입출금/통계", value: "balance" }],

      options_type: "balance",

      select: [{ label: "선택", value: null }],

      agent_name: "",
    };

    this.agentService = new AgentService();
  }

  handleCalculate(agent_name: String, begin: any, end: any) {
    this.agentService
      .agent_get_calculate(agent_name, begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE'))
      .then((data) => {
        if (data.status == "success") {
          let detail = Object.values(data.calculate);
          detail.sort(function(a: any, b: any) {
            return Date.parse(a.date) > Date.parse(b.date) ? -1 : 1;
          });

          this.setState({ details: detail });
        }
      });
  }

  handleMiniGame() {
    this.agentService.agent_get_minigame_list().then((data) => {
      if (data.status == "success") {
        let options = this.state.options;

        console.log(data.management);
        if (data.management.sportpre === "y") {
          options.push({ label: "스포츠", value: "sport_pre" });
        }
        if (data.management.sportlive === "y") {
          options.push({
            label: "라이브스포츠",
            value: "sport_live",
          });
        }
        if (data.management.casino === "y") {
          options.push({ value: "casino", label: "casino" });
        }
        if (data.management.slot === "y") {
          options.push({ label: "slot", value: "slot" });
        }
        if (data.management.fx === "y") {
          options.push({ label: "fx", value: "fx" });
        }

        for (let mini of data.miniGames) {
          options.push({ value: mini.categoryId, label: mini.categoryName });
        }

        this.setState({ options: options, management: data.management });
      }
    });
  }

  handleAgentList() {
    this.agentService.agent_get_list().then((data) => {
      if (data.status == "success") {
        let option: any = [];
        for (let agent of data.agents) {
          option.push({
            label: agent.nick,
            value: agent.agent,
            key: agent.agent,
          });
        }
        this.setState({ agents_option: option });
      }
    });
  }
  componentDidMount() {
    this.handleMiniGame();
    this.handleAgentList();

    this.handleCalculate("", this.state.begin, this.state.end);
  }

  render() {
    const RenderCalculate = () => {
      if (this.state.options_type == "balance") {
        let sum = {
          betDoUserCount: 0,
          betDoCount: 0,
          betDoBalance: 0,
          betWinUserCount: 0,
          betWinCount: 0,
          betWinBalance: 0,
          depositUserCount: 0,
          depositCount: 0,
          depositBalance: 0,
          withdrawUserCount: 0,
          withdrawCount: 0,
          withdrawBalance: 0,
        };

        for (let data of this.state.details) {
          sum["betDoUserCount"] += data.betting.betDoUserCount;
          sum["betDoCount"] += data.betting.betDoCount;
          sum["betDoBalance"] += data.betting.betDoBalance;
          sum["betWinUserCount"] += data.betting.betWinUserCount;
          sum["betWinCount"] += data.betting.betWinCount;
          sum["betWinBalance"] += data.betting.betWinBalance;

          sum["depositUserCount"] += data.balance.depositUserCount;
          sum["depositCount"] += data.balance.depositCount;
          sum["depositBalance"] += data.balance.depositBalance;
          sum["withdrawUserCount"] += data.balance.withdrawUserCount;
          sum["withdrawCount"] += data.balance.withdrawCount;
          sum["withdrawBalance"] += data.balance.withdrawBalance;
        }

        return (
          <DataTable
            value={[sum]}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
            scrollable={true}
            rowClassName={() => {
              return { "p-highlight": true };
            }}
          >
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="날짜 토탈"
              body={(rowData: any, column: any) => {
                return "토탈 ";
              }}
            />
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="충전(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.depositUserCount}/{rowData.depositCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.depositBalance)}
                    </span>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="환전(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.withdrawUserCount}/{rowData.withdrawCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.withdrawBalance)}
                    </span>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="충전-환전"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    <span
                      style={{
                        color: balanceColor(
                          rowData.depositBalance - rowData.withdrawBalance
                        ),
                      }}
                    >
                      {numTomoeny(
                        rowData.depositBalance - rowData.withdrawBalance
                      )}
                    </span>
                  </div>
                );
              }}
            />
         
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="배팅(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.betDoUserCount}/{rowData.betDoCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betDoBalance)}
                    </span>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="당첨(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.betWinUserCount}/{rowData.betWinCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betWinBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="배팅 - 당첨"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    <span
                      style={{
                        color: balanceColor(
                          rowData.betDoBalance - rowData.betWinBalance
                        ),
                      }}
                    >
                      {numTomoeny(rowData.betDoBalance - rowData.betWinBalance)}
                    </span>
                  </div>
                );
              }}
            />
          </DataTable>
        );
      } else if (this.state.options_type == "all") {
      } else {
        let sum = {
          betDoUserCount: 0,
          betDoCount: 0,
          betDoBalance: 0,
          winUserCount: 0,
          winCount: 0,
          winBalance: 0,
          loseUserCount: 0,
          loseCount: 0,
          loseBalance: 0,
          rollingUserCount: 0,
          rollingCount: 0,
          rollingBalance: 0,
        };

        for (let detail of this.state.details) {
          let data = detail.detail[this.state.options_type];
          if (data != null) {

            if(data.bet != null){
              sum["betDoUserCount"] += data.bet.userCount;
              sum["betDoCount"] += data.bet.doCount;
              sum["betDoBalance"] += data.bet.balance;
  
            }


            if(data.win != null){
              sum["winUserCount"] += data.win.userCount;
              sum["winCount"] += data.win.doCount;
              sum["winBalance"] += data.win.balance;
            }

            if (data.lose != null) {
              sum["loseUserCount"] += data.lose.userCount;
              sum["loseCount"] += data.lose.doCount;
              sum["loseBalance"] += data.lose.balance;
            }

            if (data.rolling != null) {
              sum["rollingUserCount"] += data.rolling.userCount;
              sum["rollingCount"] += data.rolling.doCount;
              sum["rollingBalance"] += data.rolling.balance;
            }
          }
          //}
        }

        return (
          <DataTable
            value={[sum]}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
            scrollable={true}
            rowClassName={() => {
              return { "p-highlight": true };
            }}
          >
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="날짜 토탈"
              body={(rowData: any, column: any) => {
                return "토탈 ";
              }}
            />
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="베팅(회원/건수/합계)	"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.betDoUserCount}/{rowData.betDoCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betDoBalance)}
                    </span>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="당첨(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.winUserCount}/{rowData.winCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.winBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="롤링"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.rollingUserCount}/{rowData.rollingCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.rollingBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="수익금"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    <span
                      style={{
                        color: balanceColor(
                          rowData.loseBalance - rowData.winBalance
                        ),
                      }}
                    >
                      {numTomoeny(rowData.loseBalance - rowData.winBalance)}
                    </span>
                  </div>
                );
              }}
            />

      
          </DataTable>
        );
      }
    };

    const RenderBet = () => {
      let options_type = this.state.options_type;
      if (options_type == "balance" || options_type == "all") {
        return <div></div>;
      }

      return (
        <DataTable
          value={this.state.details}
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
          scrollable={true}
        >
          <Column
            style={{ textAlign: "center", width: "220px" }}
            header="날짜"
            body={(rowData: any, column: any) => {
              let date = new Date(rowData.date);

              return (
                pad2(date.getMonth() + 1) +
                "-" +
                pad2(date.getDate()) +
                " " +
                pad2(date.getHours())
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "220px" }}
            header="베팅(회원/건수/합계)	"
            body={(rowData: any, column: any) => {
              if (rowData.detail[options_type] == null) {
                return (
                  <div>
                    0/ 0/ <span>0</span>
                  </div>
                );
              }

              let detail = rowData.detail[options_type].bet;
              if (detail == null) {
                return (
                  <div>
                    0/ 0/ <span>0</span>
                  </div>
                );
              }
              return (
                <div>
                  {detail.userCount}/{detail.doCount}/
                  <span style={{ color: "#e91224" }}>
                    {numTomoeny(detail.balance)}
                  </span>
                </div>
              );
            }}
          />
          <Column
            style={{ textAlign: "center", width: "220px" }}
            header="당첨(회원/건수/합계)"
            body={(rowData: any, column: any) => {
              if (rowData.detail[options_type] == null) {
                return (
                  <div>
                    0/ 0/ <span>0</span>
                  </div>
                );
              }
              let detail = rowData.detail[options_type].win;
              if (detail == null) {
                return (
                  <div>
                    0/ 0/ <span>0</span>
                  </div>
                );
              }

              return (
                <div>
                  {detail.userCount}/{detail.doCount}/
                  <span style={{ color: "#e91224" }}>
                    {numTomoeny(detail.balance)}
                  </span>
                </div>
              );
            }}
          />


        <Column
            style={{ textAlign: "center", width: "200px" }}
            header="롤링(일부지원)"
            body={(rowData: any, column: any) => {
              if (rowData.detail[options_type] == null) {
                return (
                  <div>
                    {0}/{0}/<span style={{ color: "#e91224" }}>{0}</span>
                  </div>
                );
              }

              let detail = rowData.detail[options_type].rolling;

              if (rowData.detail[options_type] == null || detail == null) {
                return (
                  <div>
                    {0}/{0}/<span style={{ color: "#e91224" }}>{0}</span>
                  </div>
                );
              }

              return (
                <div>
                  {detail.userCount}/{detail.doCount}/
                  <span style={{ color: "#e91224" }}>
                    {numTomoeny(detail.balance)}
                  </span>
                </div>
              );
            }}
          />


          {/* <Column
            style={{ textAlign: "center", width: "220px" }}
            header="낙점(회원/건수/합계)"
            body={(rowData: any, column: any) => {
              if (rowData.detail[options_type] == null) {
                return (
                  <div>
                    0/ 0/ <span>0</span>
                  </div>
                );
              }
              let detail = rowData.detail[options_type].lose;
              if (detail == null) {
                detail = rowData.detail[options_type].bet;
              }

              if (detail == null) {
                return (
                  <div>
                    0/ 0/ <span>0</span>
                  </div>
                );
              }
              return (
                <div>
                  {detail.userCount}/{detail.doCount}/
                  <span style={{ color: "#e91224" }}>
                    {numTomoeny(detail.balance)}
                  </span>
                </div>
              );
            }}
          /> */}

          <Column
            style={{ textAlign: "center", width: "220px" }}
            header="수익금"
            body={(rowData: any, column: any) => {
              if (rowData.detail[options_type] == null) {
                return (
                  <div>
                    0/ 0/ <span>0</span>
                  </div>
                );
              }
              let lose = rowData.detail[options_type].lose;
              if (lose == null) {
                lose = rowData.detail[options_type].bet;
              }

              let win = rowData.detail[options_type].win;

              if (win == null) {
                return (
                  <div>
                    0/ 0/ <span>0</span>
                  </div>
                );
              }

              return (
                <div>
                  {rowData.balance.withdrawUserCount}/
                  {rowData.balance.withdrawCount}/
                  <span
                    style={{ color: balanceColor(lose.balance - win.balance) }}
                  >
                    {numTomoeny(lose.balance - win.balance)}
                  </span>
                </div>
              );
            }}
          />
        </DataTable>
      );
    };


    const RenderBalance = () => {
      if (this.state.options_type == "balance") {
        return (
          <DataTable
            value={this.state.details}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
            scrollable={true}
          >
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="날짜"
              body={(rowData: any, column: any) => {
                let date = new Date(rowData.date);

                return (
                  pad2(date.getMonth() + 1) +
                  "-" +
                  pad2(date.getDate()) +
                  " " +
                  pad2(date.getHours())
                );
                //return rowData.date;
              }}
            />

            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="충전(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.balance.depositUserCount}/
                    {rowData.balance.depositCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.balance.depositBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="환전(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.balance.withdrawUserCount}/
                    {rowData.balance.withdrawCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.balance.withdrawBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="충전 - 환전"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    <span
                      style={{
                        color: balanceColor(
                          rowData.balance.depositBalance -
                            rowData.balance.withdrawBalance
                        ),
                      }}
                    >
                      {numTomoeny(
                        rowData.balance.depositBalance -
                          rowData.balance.withdrawBalance
                      )}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="배팅(회원/건수/합계)	"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.betting.betDoUserCount}/
                    {rowData.betting.betDoCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betting.betDoBalance)}
                    </span>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="당첨(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.betting.betWinUserCount}/
                    {rowData.betting.betWinCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betting.betWinBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="배팅 - 당첨"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    <span
                      style={{
                        color: balanceColor(
                          rowData.betting.betDoBalance -
                            rowData.betting.betWinBalance
                        ),
                      }}
                    >
                      {numTomoeny(
                        rowData.betting.betDoBalance -
                          rowData.betting.betWinBalance
                      )}
                    </span>
                  </div>
                );
              }}
            />
          </DataTable>
        );
      }
    };


    
    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">에이전트 기록</div>

                <div className="p-col-12 ">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-2">
                      <Dropdown
                        value={this.state.agent_name}
                        options={this.state.agents_option}
                        placeholder="에이전트 선택"
                        onChange={(e) => {
                          this.setState({ agent_name: e.value });
                          this.handleCalculate(
                            e.value,
                            this.state.begin,
                            this.state.end
                          );
                        }}
                      />
                    </div>

                    <div className="p-col-12 p-md-2">
                      <Calendar
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={this.state.begin}
                        onChange={(e) => this.setState({ begin: e.value })}
                        showWeek={false}
                      />
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Calendar
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={this.state.end}
                        onChange={(e) => this.setState({ end: e.value })}
                        showWeek={false}
                      />
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Button
                        label="검색"
                        onClick={() => {
                          this.handleCalculate(
                            this.state.agent_name,
                            this.state.begin,
                            this.state.end
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 ">
                  <SelectButton
                    value={this.state.options_type}
                    options={this.state.options}
                    onChange={(e) => {
                      if (e.value == null) {
                        return;
                      }
                      this.setState({ options_type: e.value });
                    }}
                  />
                </div>
              </div>
              <div className="card">
                {RenderCalculate()}
                {RenderBalance()}
                {RenderBet()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
