import React, { Component,useState, useEffect, useRef } from 'react';

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
// import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
// import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';

import { OverlayPanel } from 'primereact/overlaypanel';

import { confirmAlert } from "react-confirm-alert"; // Import

import { UserService } from "../../service/user.service";
import { BalanceService } from "../../service/balance.service";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail,
  ConvertNick

} from "../../utility/help";



interface Props {

  level: number;
  //dataType: any;
  mode: string;
  userTableValue?: any;
  handleUseStauseChange: (userId: string, stause: string) => any;
  SortTable: (field: any, sort: any) => any;
}

interface State {
  balances: any
  balance: any
  userID: any
  mode: any
  userTableValue?: any;
}



export class UserTable extends Component<Props, State> {
  userService = new UserService();
  balanceService: BalanceService = new BalanceService();

  giveBalance = React.createRef() as any;

  // };

  constructor(props: Props) {
    super(props);
    this.state = {
      mode : 'charge',
      userID : '',
      balance : 0,
      balances: [
        { name: "10,000", value: 10000 },
        { name: "50,000", value: 50000 },
        { name: "100,000", value: 100000 },
        { name: "500,000", value: 500000 },
        { name: "1,000,000", value: 1000000 },
        { name: "초기화 ", value: 0 },
      ],
    }

  }

  

  handleDoDeposit() {
    confirmAlert({
      title: "지급",
      message: "해당 벨런스를 유저에게 지급하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_deposit_to_user(this.state.balance, this.state.userID)
              .then((s: any) => {
                console.log(s);
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.handleBalanceUpdate(this.state.userID);
                           this.setState({balance : 0}) 
                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "지급",
                    message: "보유중인 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "지급",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  handleDoWithdraw() {
    confirmAlert({
      title: "회수",
      message: "해당 벨런스를 유저에게 회수하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_withdraw_to_user(this.state.balance, this.state.userID)
              .then((s: any) => {
                if (s.status === "success") {
                  confirmAlert({
                    title: "회수",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          window.location.reload()

                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "회수",
                    message: "유저 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "회수",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }
  componentDidMount() {
    //    this.GetUserList(0, this.props.dataType, 1);
  }

  // GetUserList = (page: any, type: any, sort: any) => {
  //   console.log(page, sort);
  //   this.userService.get_user_list(page, type, sort).then(data => {
  //     this.setState({ userTableValue: data.users });
  //   });
  // };

  handleBalanceUpdate = (id: string) => {
    this.userService.get_in_game_balance(id).then((s: any) => {
      if (s.status === "success") {
        let user = this.props.userTableValue.find((data : any) => data.id == s.id);
        if (user != null) {
          user.balance = s.balance;
          user.in_balance = 0;
        }
      }
      let user = this.props.userTableValue.find((data: any) => data.id == s.id);
      if (user != null) {
        user.update = null;
        this.setState({ userTableValue: this.props.userTableValue });
      }
    });
  };

  render() {
    let RenderReg = () => {
      if (this.props.mode != "reg") {
        return <div></div>;
      }

      return (
        <Column
          style={{ textAlign: "center" }}
          header="상태 변경"
          body={(rowData : any) => {
            return (
              <div className="p-grid form-group">
                <Button
                  label="승인"
                  className="p-button-success"
                  onClick={() => {
                    this.props.handleUseStauseChange(rowData.id, "used");
                  }}
                />
                <Button
                  label="대기"
                  className="p-button-info"
                  onClick={() => {
                    this.props.handleUseStauseChange(rowData.id, "wait");
                  }}
                />
                <Button
                  label="삭제"
                  className="p-button-warning"
                  onClick={() => {
                    this.props.handleUseStauseChange(rowData.id, "remove");
                  }}
                />
              </div>
            );
          }}
        />
      );
    };
    let headeBalance = (up: any, fieldUp: any) => {
      return (
        <div>
          <span>
            <a
              style={{ color: "red" }}
              onClick={() => this.props.SortTable(fieldUp, 1)}
            >
              ▲
            </a>
            {up}
            <a
              style={{ color: "blue" }}
              onClick={() => this.props.SortTable(fieldUp, -1)}
            >
              ▼
            </a>
          </span>
        </div>
      );
    };

    return (
      <div className="p-col-12">


        <OverlayPanel ref={this.giveBalance}  id="overlay_panel" style={{width: '500px'}} showCloseIcon={false}>
          
            <div className="md-inputfield">
              <span className="p-float-label" style={{padding : '20px'}}>
                <div className="p-grid form-group" >
                  <div className="p-field-radiobutton" style={{paddingRight : '50px'}}>
                      <RadioButton inputId="city1" name="city1" value="charge" onChange={(e) => this.setState({mode : 'charge'})} checked={this.state.mode === 'charge'}  />
                      <label htmlFor="city1" style={{ paddingTop :'15px'}}>충전</label>
                  </div>
                  <div className="p-field-radiobutton" style={{paddingRight : '50px'}}>
                      <RadioButton inputId="city2" name="city2" value="exchange" onChange={(e) => this.setState({mode : 'exchange'})} checked={this.state.mode === 'exchange'} />
                      <label htmlFor="city2" style={{paddingLeft : '80px', paddingTop :'15px'}}>환전</label>
                  </div>

                  <InputText id="username" onChange={(e: any) => this.setState({ balance: e.target.value })} value={this.state.balance} />
                  <Button className="p-button-sm p-button-success"  label="지급/회수"onClick={()=>{ 
                    if(this.state.mode === 'charge'){
                      this.handleDoDeposit()
                    }
                    else if(this.state.mode === 'exchange'){
                      this.handleDoWithdraw()
                    }

                  }}  />
                  </div> 
                </span>

              <div className="p-grid form-group" style={{padding : '20px'}} >
                <Button className="p-button-sm p-button-outlined" label="10,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(10000) }) }}/>
                <Button className="p-button-sm p-button-outlined"  label="50,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(50000) }) }} />
                <Button className="p-button-sm p-button-outlined"  label="100,000" onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(100000) }) }} />
                <Button className="p-button-sm p-button-outlined"  label="500,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(500000) }) }}/>
                <Button className="p-button-sm p-button-outlined"  label="1,000,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(1000000) }) }}/>
                <Button className="p-button-sm p-button-danger"  label="정정"onClick={()=>{ this.setState({balance : 0 }) }}  />
              </div> 
            </div> 
   
        </OverlayPanel>


        <DataTable
          value={this.props.userTableValue}
          rows={20}
          sortOrder={-1}
          scrollable={true}
        >
          <Column
            style={{ textAlign: "center", width: "100px" }}
            header="아이디"
            body={(rowData : any) => {
              return (
                <div style={{ color: rowData.color }}>
                  <div
                    onClick={() => {
                      OpenUserDetail(rowData.id);
                    }}
                  >
                    {rowData.id}
                  </div>
                </div>  
              );
            }}
          />  

          {
            process.env.REACT_APP_API_CUSTOM_MODE === 'THB' && (
              <Column
                style={{ textAlign: "center", width: "100px" }}
                header="전화번호"
                body={(rowData : any) => {
                  return (
                    <div>
                      <div id={"contextMenu"}>{rowData.phone}</div>
                    </div>
                  );
                }}
              />
            )
          }




          <Column
            style={{ textAlign: "center", width: "100px" }}
            header="이름"
            body={(rowData : any) => {
              return (
                <div>
                  <div id={"contextMenu"}>{rowData.bankowner}</div>
                </div>
              );
            }}
          />


          <Column
            style={{ textAlign: "center", width: "120px" }}
            // header="보유/포인트"
            header={headeBalance("보유금액", "balance")}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>
                    {numTomoeny(rowData.balance + rowData.in_balance)}
                  </span>

                  {rowData.update == null ? (
                    <i
                      className="pi-md-refresh"
                      onClick={() => {
                         this.handleBalanceUpdate(rowData.id);
                        rowData.update = true;
                      }}
                    ></i>
                  ) : (
                    <ProgressSpinner
                      strokeWidth={"2"}
                      style={{ width: "10px" }}
                    />
                  )}
                </div>
              );
            }}
            // sortFunction={}
          />

        <Column
            style={{ textAlign: "center", width: "120px" }}
            // header="보유/포인트"
            header={"충전/환전"}
            field="balance"
            body={(rowData : any) => {


              return (
                <Button type="button" label={'충/환전'} onClick={(e : any) => {
                 
                  
                  this.setState({userID : rowData.id})  
                  this.giveBalance.current.toggle(e)

                }} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />

                  // <OverlayBalance></OverlayBalance>
              );
            }}
          />
         

          
          <Column
            style={{ textAlign: "center", width: "180px" }}
            header={"접속 IP"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{rowData.loginip}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "180px" }}
            header={"가입일"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{convertDate(rowData.regdate)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "180px" }}
            header={"접속일"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{convertDate(rowData.logindate)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "left", width: "600px" }}
            header={"에이전트"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span dangerouslySetInnerHTML={{ __html: ConvertNick(this.props.level,rowData.agent_tree, rowData.agent_tree_ko) }} ></span>
                </div>
              );
            }}
          />

          {RenderReg()}
        </DataTable>


      </div>
    );
  }
}
