import React, { Component } from 'react';
import GitInfo from 'react-git-info/macro';


export class AppFooter extends Component {

    render() {
        const gitInfo = GitInfo();

        return  <div className="layout-footer">
                    <div className="p-grid">
                        <div className="p-col">
                            <img src="assets/layout/images/logo-white.svg" alt="sapphire-layout"/>
                            <div className="layout-footer-appname">V3  {gitInfo.commit.date} {gitInfo.commit.hash} {gitInfo.branch}</div>
                            {process.env.REACT_APP_API_ROOLING_EXCHANGE_TO_TOP_AGENT_BALANCE === 'Y' && <div className="layout-footer-appname">Rolling Exchange To Top Anget Balance Version</div>}
                            {process.env.REACT_APP_API_CALCULATE_WIN_LOSE === 'Y' && <div className="layout-footer-appname">CALCULATE WIN LOSE Version</div>}
                            {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'STY' && <div className="layout-footer-appname">CALCULATE REAL ROLLING Version</div>}
                            {process.env.REACT_APP_API_CALCULATE_BANLACE === 'Y' && <div className="layout-footer-appname">CALCULATE BANLACE Version</div>}
                            

                        </div>
                        <div className="p-col p-col-align-right">
                            <span className="pi-md-copyright"></span>
                            <span>All Rights Reserved</span>
                        </div>
                    </div>
                </div>
    }
}