import React, { Component, Children } from "react";

import { PanelMenu } from "primereact/panelmenu";
import { TieredMenu } from "primereact/tieredmenu";
import { Menu } from "primereact/menu";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { Password } from "primereact/password";
import { InputMask } from "primereact/inputmask";
import { Spinner } from "primereact/spinner";
import { RadioButton } from "primereact/radiobutton";
import { Slider } from "primereact/slider";
import { MultiSelect } from "primereact/multiselect";
import { ListBox } from "primereact/listbox";

import { confirmAlert } from "react-confirm-alert";
import { TreeTable } from "primereact/treetable";
import { BalanceService } from "../service/balance.service";

import { OverlayPanel } from 'primereact/overlaypanel';
import { AlertYesNo } from "../share/alert-yes-no";
import { AgentService } from "../service/agent.service";
import {
  converAgentStatus,
  ConvertGameText,
  numTomoeny,
  OpenAgentDetail
} from "../utility/help";
import Popup from "reactjs-popup";

interface NewAgent {
  parentId: string;
  agent: string;
  nick: string;
  pass: string;
  phone: string;
  bankname: string;
  banknum: string;
  bankowner: string;

  items: NewAgent[];
}

interface State {
  newAgent: any;
  topAgents: any;
  agents: any[];
  benefits: any[];

  isVisible: boolean;
  expandedKeys: any;

  agentId: any;
  balances: any
  balance: any
  mode: any
  level: any

}

function AttachTree(parentId, trees, childrens) {
  for (let agent of trees) {
    if (agent.data.agent == parentId) {
      if (agent.children != null) {
        agent.children = [];
      }
      for (let info of childrens) {
        agent.children.push({ key: info.agent, data: info, children: [] });
      }
    } else {
      AttachTree(parentId, agent.children, childrens);
    }
  }
}

export class AgentCasino extends Component<any, State> {
  agentService: AgentService = new AgentService();
  balanceService = new BalanceService();

  giveBalance = React.createRef() as any;

  toggleApplications() {
    let expandedKeys = { ...this.state.expandedKeys };
    if (expandedKeys["0"]) delete expandedKeys["0"];
    else expandedKeys["0"] = true;

    this.setState({ expandedKeys: expandedKeys });
  }

  constructor(props: any) {
    super(props);

    this.state = {
      mode : 'charge',
      agentId : '',

      isVisible: false,
      newAgent: {},

      topAgents: [],
      expandedKeys: {},
      benefits: [],

      agents: [],
      balance : 0,
      level : 4,
      balances: [
        { name: "10,000", value: 10000 },
        { name: "50,000", value: 50000 },
        { name: "100,000", value: 100000 },
        { name: "500,000", value: 500000 },
        { name: "1,000,000", value: 1000000 },
        { name: "초기화 ", value: 0 },
      ],
    };
    this.handleGetAgentTree();
    this.handleAgentBenefit();
  }

  componentDidMount() {

  }

  
  

  handleDoDeposit() {
    confirmAlert({
      title: "지급",
      message: "해당 벨런스를 에이전트에게 지급하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_deposit_to_agent(this.state.balance, this.state.agentId)
              .then((s: any) => {
                console.log(s);
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          // this.handleBalanceUpdate(userID);
                          window.location.reload()
                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "지급",
                    message: "보유중인 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "지급",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  handleDoWithdraw() {
    confirmAlert({
      title: "회수",
      message: "해당 벨런스를 에이전트에게 회수하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_withdrawt_to_agent(this.state.balance, this.state.agentId)
              .then((s: any) => {
                if (s.status === "success") {
                  confirmAlert({
                    title: "회수",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          window.location.reload()

                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "회수",
                    message: "에이전트 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "회수",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  handleAgentBenefit = () => {
    this.agentService.agent_get_agent_benefit().then((s) => {
      console.log(s);
      if (s.status == "success") {
        
        this.setState({ benefits: s.benefit });
      }
    });
  };

  handleGetAgentTree = () => {
    this.agentService.agent_get_all_agent().then((s) => {
      if (s.status == "success") {
        // let agents: any = [];
        // for (let agent of s.agents.sort(function(a, b)  {
        //   if(a > b) return 1;
        //   if(a === b) return 0;
        //   if(a < b) return -1;
        // })) {
        //   console.log(agent.agent);
        //   agents.push({
        //     key: agent.agent,
        //     data: agent,
        //     children: [],
        //   });
        // }
        let agents  = s.agents.sort(function(a, b)  {
          if(a.agent_tree > b.agent_tree) return 1;
          if(a.agent_tree === b.agent_tree) return 0;
          if(a.agent_tree < b.agent_tree) return -1;
        })
        if(agents != null && agents[0] != null){
          this.setState({ agents: agents, level : s.level});
        }
        else{
          this.setState({ agents: agents, level : s.level });
        }
      }
    });
  };

  handleSaveAgent = () => {
    let newAgent = this.state.newAgent;

    if (
      newAgent.agent == null ||
      newAgent.nick == null ||
      newAgent.pass == null ||
      newAgent.phone == null ||
      newAgent.agent == "" ||
      newAgent.nick == "" ||
      newAgent.pass == "" ||
      newAgent.phone == ""
    ) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 정보를 정확히 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }
    
    if(isNaN(Number(this.state.newAgent['agent_slot_rolling'] )) || typeof Number(this.state.newAgent['agent_slot_rolling'] ) !== 'number') {
      confirmAlert({
        title: "에이전트",
        message: "슬롯 롤링에 숫자를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },
      
        ],
      });
      return 
    }

    // if(typeof this.state.newAgent['balance_losing'] !== 'number') {
    //   return 
    // }

    confirmAlert({
      title: "에이전트",
      message: "에이전트 생성/수정을 수행하시겠습니까 ?.",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.handleEditOrMakeAgent();
          },
        },
        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  handleGetDownAgent = (agent: string, level: number) => {
    this.agentService.agent_get_down_agent(agent, level).then((data) => {
      if (data.status == "success") {
        if (data.agents.length >= 1) {
          AttachTree(agent, this.state.agents, data.agents);
          let expandedKeys = { ...this.state.expandedKeys };
          if (expandedKeys[`${agent}`]) delete expandedKeys[`${agent}`];
          else expandedKeys[`${agent}`] = true;

          this.setState({ expandedKeys: expandedKeys });
        }
      }
    });
  };

  handleEditOrMakeAgent = () => {

    this.agentService.agent_save_agent(this.state.newAgent).then((s) => {
      if (s.status == "success") {
        confirmAlert({
          title: "에이전트",
          message:
            "에이전트 생성 / 수정 에 성공하셨습니다. / 에이전트 생성시 승인을 대기하셔야합니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      } else if (s.status == "agent") {
        confirmAlert({
          title: "에이전트",
          message: "에이전트 아이디를 입력해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      } else if (s.status == "already") {
        confirmAlert({
          title: "에이전트",
          message: "해당 아이디는 사용할수 없는 아이디입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      } else if (s.status == "benefit") {
        confirmAlert({
          title: "에이전트",
          message: "프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      } else {
        confirmAlert({
          title: "에이전트",
          message: "관리자에게 문의 부탁드림니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    let newAgent = this.state.newAgent;

    const RenderBenefits = () => {
      const text = {
        balance_losing: "충/환전 루징",
        agent_sportwserwin_rolling: "스포츠 Win 롤링",
        agent_sportuserlose_rolling: "스포츠 Lose 롤링",
        agent_livewin_rolling: "스포츠 라이브 Win 롤링",
        agent_livelose_rolling: "스포츠 라이브 Lose 롤링",

        agent_miniwin_rolling: "미니게임 Win 롤링",
        agent_minilose_rolling: "미니게임 Lose 롤링",

        agent_casino_rolling: "카지노 롤링",
        agent_slot_rolling: "슬롯 롤링",
      };

      return ['agent_slot_rolling', 'agent_casino_rolling', 'balance_losing'].map((s) => {
        let value = this.state.newAgent[s];
        if(s === 'balance_losing') return <></>

        return (
          <div className="p-col-12 p-md-12" key={s}>
            <div className="md-inputfield ">
              <InputText
                id="input"
                value={value}
                onChange={(even: any) => {

                  let newAgent = this.state.newAgent;
                  newAgent[s] = even.target.value;
                  this.setState({ newAgent: newAgent });
                }}
              />
              <label htmlFor="input">{text[s]}</label>
            </div>
            <br />
          </div>
        );
      });
    };

    const RenderTitle = () => {
      if (newAgent.parentId != "") {
        return (
          <div className="card-title">{newAgent.parentId} 하단 에전트 생성</div>
        );
      }

      return <div className="card-title">에이전트 생성</div>;
    };


    const rowClass = (data) => {

      if (data.level === 1) return { 'row-level1': data.level === 1 }
      if (data.level === 2) return { 'row-level2': data.level === 2 }
      if (data.level === 3) return { 'row-level3': data.level === 3 }


      return { 'row-level4': data.level === 4 }
  }

    const RenderEdit = () => {
      // if (newAgent != null) {
      //   return <> </>;
      // }

      return (
        <Popup
          open={this.state.isVisible}
          position="right center"
          onClose={() => {
            this.setState({ isVisible: false });
          }}

          overlayStyle={{ zIndex : 10}}
          contentStyle={{backgroundColor : '#fff', width : '360px', zIndex : 10}}
        > 
          <div className="">
            {RenderTitle()}
            
            <div className="p-col-12 p-md-2"></div>
            <div className="p-col-12 p-md-12">
              <div className="p-inputgroup">
                <div className="p-col-12 p-md-4">
                  <Button
                    label="저장 하기"
                    icon="pi-md-launch"
                    onClick={() => this.handleSaveAgent()}
                  />
                </div>
              </div>
            </div>
            
            <div className="p-col-12 p-md-12">
              <div className="md-inputfield ">
                <InputText
                  id="input"
                  value={newAgent.agent || ""}
                  onChange={(even: any) => {
                    newAgent.agent = even.target.value;
                    this.setState({ newAgent: newAgent });
                  }}
                />
                <label htmlFor="input">아이디</label>
              </div>
              <br />
            </div>

            <div className="p-col-12 p-md-12">
              <div className="md-inputfield ">
                <InputText
                  id="input"
                  value={newAgent.nick || ""}
                  onChange={(even: any) => {
                    newAgent.nick = even.target.value;
                    this.setState({ newAgent: newAgent });
                  }}
                />
                <label htmlFor="input">닉네임</label>
              </div>
              <br />
            </div>

            <div className="p-col-12 p-md-12">
              <div className="md-inputfield ">
                <InputText
                  id="input"
                  value={newAgent.pass || ""}
                  onChange={(even: any) => {
                    newAgent.pass = even.target.value;
                    this.setState({ newAgent: newAgent });
                  }}
                />
                <label htmlFor="input">비밀번호</label>
              </div>
              <br />
            </div>

            <div className="p-col-12 p-md-12">
              <div className="md-inputfield ">
                <InputText
                  id="input"
                  value={newAgent.phone || ""}
                  onChange={(even: any) => {
                    newAgent.phone = even.target.value;
                    this.setState({ newAgent: newAgent });
                  }}
                />
                <label htmlFor="input">전화번호</label>
              </div>
              <br />
            </div>

            <div className="p-col-12 p-md-12">
              <div className="md-inputfield ">
                <InputText
                  id="input"
                  value={newAgent.bankname || ""}
                  onChange={(even: any) => {
                    newAgent.bankname = even.target.value;
                    this.setState({ newAgent: newAgent });
                  }}
                />
                <label htmlFor="input">은행명</label>
              </div>
              <br />
            </div>

            <div className="p-col-12 p-md-12">
              <div className="md-inputfield ">
                <InputText
                  id="input"
                  value={newAgent.banknum || ""}
                  onChange={(even: any) => {
                    newAgent.banknum = even.target.value;
                    this.setState({ newAgent: newAgent });
                  }}
                />
                <label htmlFor="input">계좌번호</label>
              </div>
              <br />
            </div>

            <div className="p-col-12 p-md-12">
              <div className="md-inputfield ">
                <InputText
                  id="input"
                  value={newAgent.bankowner || ""}
                  onChange={(even: any) => {
                    newAgent.bankowner = even.target.value;
                    this.setState({ newAgent: newAgent });
                  }}
                />
                <label htmlFor="input">입금자명</label>
              </div>
              <br />
            </div>

            {RenderBenefits()}

          </div>
        </Popup>
      );
    };
    
    // const RenderAgent = () =>{
    //   return this.state.agents??[].map(col=> {
    //     return   <Column field="agent" header="부본사"  style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8', }}
    //     bodyStyle={{  textAlign: "center", }}
    //     body={(node, column) => {
    //       if(node.level == 2) {
    //         return (
    //           <>
    //           <div style={{  padding: '10px', textAlign: "center"}} onClick={()=> OpenAgentDetail(node.agent)}>
    //             <span className="_partner_div_2">부</span>{node.agent}</div>
    //           </>
    //         )
    //       }
    //       return (<div></div>)
  
    //     }}></Column>
    //   });
    // }

    return (
      <div>
        
        <OverlayPanel ref={this.giveBalance}  id="overlay_panel" style={{width: '500px'}} showCloseIcon={false}>
          
            <div className="md-inputfield">
              <span className="p-float-label" style={{padding : '20px'}}>
                <div className="p-grid form-group" >
                  <div className="p-field-radiobutton" style={{paddingRight : '50px'}}>
                      <RadioButton inputId="city1" name="city1" value="charge" onChange={(e) => this.setState({mode : 'charge'})} checked={this.state.mode === 'charge'}  />
                      <label htmlFor="city1" style={{ paddingTop :'15px'}}>지급</label>
                  </div>
                  <div className="p-field-radiobutton" style={{paddingRight : '50px'}}>
                      <RadioButton inputId="city2" name="city2" value="exchange" onChange={(e) => this.setState({mode : 'exchange'})} checked={this.state.mode === 'exchange'} />
                      <label htmlFor="city2" style={{paddingLeft : '80px', paddingTop :'15px'}}>회수</label>
                  </div>

                  <InputText id="username" onChange={(e: any) => this.setState({ balance: e.target.value })} value={this.state.balance} />
                  <Button className="p-button-sm p-button-success"  label="지급/회수"onClick={()=>{ 
                    if(this.state.mode === 'charge'){
                      this.handleDoDeposit()
                    }
                    else if(this.state.mode === 'exchange'){
                      this.handleDoWithdraw()
                    }

                  }}  />
                  </div> 
                </span>

              <div className="p-grid form-group" style={{padding : '20px'}} >
                <Button className="p-button-sm p-button-outlined" label="10,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(10000) }) }}/>
                <Button className="p-button-sm p-button-outlined"  label="50,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(50000) }) }} />
                <Button className="p-button-sm p-button-outlined"  label="100,000" onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(100000) }) }} />
                <Button className="p-button-sm p-button-outlined"  label="500,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(500000) }) }}/>
                <Button className="p-button-sm p-button-outlined"  label="1,000,000"  onClick={()=>{ this.setState({balance : Number(this.state.balance) + Number(1000000) }) }}/>
                <Button className="p-button-sm p-button-danger"  label="정정"onClick={()=>{ this.setState({balance : 0 }) }}  />
              </div> 
            </div> 
   
        </OverlayPanel>

        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div>
                  <div className="p-md-6">
                    <div className="p-grid form-group">
                      {
                         this.state.level < 4 && (
                          <AlertYesNo
                          type="success"
                          className="p-button-success"
                          btnLabel="최상위 에이전트 생성"
                          title="최상위 에이전트"
                          message="에이전트를 생성하시겠습니까 ?"
                          call={() => {

                            let newAgent = this.state.newAgent;
                            newAgent.agent = '';
                            newAgent.nick = '';
                            this.setState({ isVisible: true , newAgent: newAgent});
                            // this.handleMakeTopAgent();
                          }}
                        ></AlertYesNo>
                         )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-md-12">
              <div className="card card-w-title">
              <div className="datatable-style-demo">

                  <DataTable
                    scrollable={true}
                    value={this.state.agents}
                    rows={999}
                    sortOrder={-1}
                    style={{  padding: '0px'}}
                    rowClassName={rowClass}
                  >

                      {
                        this.state.level < 2 && (
                          <Column field="agent" header="에이전트"  style={{ borderRight: '1px solid #d8d8d8', textAlign: "center", width: "120px", padding: '0px'}}
                          bodyStyle={{   borderRight: '1px solid #d8d8d8',textAlign: "center", padding: '0px'  }}
                          body={(node, column) => {
                            if(node.level == 2) {
                              return (
                                <>
                                <div style={{  padding: '10px', textAlign: "center"}} onClick={()=> OpenAgentDetail(node.agent)}>{node.agent}</div>
                                </>
                              )
                            }
                            return <div></div>
                          }}></Column>
                        )
                      }

                      {
                        this.state.level < 3 && (
                          <Column field="agent" header="에이전트"  style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8'}}
                              bodyStyle={{  textAlign: "center",padding: '0px', borderRight: '1px solid #d8d8d8' }}
                              body={(node, column) => {
                              if(node.level == 3) {
                                return (<div style={{  padding: '10px', textAlign: "center"}} onClick={()=> OpenAgentDetail(node.agent)}>{node.agent}</div>)
                              }
                              return (<div></div>)

                            }}
                          ></Column>)
                        }
                        
                        {
                        this.state.level < 4 && (
                          <Column field="agent" header="에이전트"  style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
                              bodyStyle={{  textAlign: "center",padding: '0px', borderRight: '1px solid #d8d8d8' }}
                              body={(node, column) => {
                              if(node.level == 4) {
                                return (<div style={{  padding: '10px', textAlign: "center"}} onClick={()=> OpenAgentDetail(node.agent)}>{node.agent}</div>)
                              }
                              return (<div></div>)

                            }}
                          ></Column>
                        )
                        }
                    <Column field="nick" header="nick" style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}} 
                        bodyStyle={{  textAlign: "center", padding: '0px'}}
                        ></Column>
                    <Column
                      style={{ textAlign: "center", width: "120px", padding: '0px' , borderRight: '1px solid #d8d8d8'}}
                      header="보유금"
                      bodyStyle={{  textAlign: "center", padding: '0px'}}
                      body={(node, column) => {
                        return (
                          <div>{numTomoeny(Number(node.balance))}</div>
                        );
                      }}
                    ></Column>
                    <Column
                      style={{ textAlign: "center", width: "120px", padding: '0px' , borderRight: '1px solid #d8d8d8'}}
                      header={"충전/환전"}
                      field="balance"
                      bodyStyle={{  textAlign: "center", padding: '0px'}}
                      body={(rowData : any) => {


                        return (
                          <Button type="button" label={'지급/회수'} onClick={(e : any) => {
                          
                             this.setState({agentId : rowData.agent})  
                             this.giveBalance.current.toggle(e)

                          }} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />

                            // <OverlayBalance></OverlayBalance>
                        );
                      }}
                    />
                  
                    <Column
                      style={{ textAlign: "center",width: "120px", borderRight: '1px solid #d8d8d8'}}
                      header="롤링"
                      bodyStyle={{  textAlign: "center", padding: '0px'}}
                      body={(node, column) => {
                          return (
                            <div>{numTomoeny(Number(node.rolling))}</div>
                          );
                        }}
                    ></Column>
                    
                    <Column
                      style={{ width: "120px", borderRight: '1px solid #d8d8d8', textAlign: "center",}}
                      bodyStyle={{  textAlign: "center", padding: '0px'}}

                      header="상태"
                      body={(node, column) => {
                        return <div>{converAgentStatus(node.status)}</div>;
                      }}
                    ></Column>
                    <Column
                      style={{ width: "120px", borderRight: '1px solid #d8d8d8'}}
                      bodyStyle={{  textAlign: "center", padding: '0px'}}
                      body={(node, column) => {
                        let root_agent = node.agent;
                        let level = node.level;

                        if(node.level >= 4){ return <></> }

                        return (
                          <div
                            className="p-grid form-group"
                            key={"btn" + root_agent}
                          >
                            {
                              process.env.REACT_APP_API_CUSTOM === 'Y' && (
                                <>
                                  <div className="p-col-12 p-md-4">
                                    <Button
                                      onClick={() => {
                                        this.handleGetDownAgent(root_agent, level);
                                      }}
                                      type="button"
                                      icon="pi pi-search"
                                      className="p-button-success"
                                    ></Button>
                                  </div>
                                  <div className="p-col-12 p-md-4">
                                    <Button
                                      type="button"
                                      icon="pi-md-edit"
                                      className="p-button-danger"
                                      onClick={() => {
                                        this.setState({
                                          newAgent: node.data,
                                          isVisible: true,
                                        });
                                      }}
                                    ></Button>
                                  </div>
                                </>
                              )

                            }
                        
                            <div className="p-col-12 p-md-4">
                              <Button
                                onClick={() => {
                                  confirmAlert({
                                    title: root_agent + "에이전트",
                                    message:
                                      root_agent +
                                      " 의 하단 에이전트를 생성하시겠습니까 ?.",
                                    buttons: [
                                      {
                                        label: "확인",
                                        onClick: () => {
                                          let newAgent = this.state.newAgent;
                                          newAgent.agent = '';
                                          newAgent.nick = '';
                                          if(newAgent.parentId !== root_agent){
                                             newAgent.parentId = root_agent
                                          }
                                          
                                          this.setState({
                                            newAgent: newAgent,
                                            isVisible: true,

                                          });
                                        },
                                      },
                                      {
                                        label: "취소",
                                        onClick: () => {},
                                      },
                                    ],
                                  });
                                }}
                                type="button"
                                icon="pi-md-exposure-plus-1"
                                className="p-button-warning"
                              ></Button>
                            </div>
                          </div>
                        );
                      }}
                    />
                  </DataTable>
            </div>

              </div>
              {RenderEdit()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
