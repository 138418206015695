import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { InputText } from "primereact/components/inputtext/InputText";
import { AppMenu } from "./AppMenu";
import cookie from "react-cookies";
import { AgentService } from "./service/agent.service";

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers
} from "./utility/help";
import { updateIf } from "typescript";

interface BarProps {
  menuActive: any;
  topbarUserMenuActive: any;

  model: any;

  horizontal: any;
  onRootMenuItemClick: (event: any) => any;
  onMenuItemClick: (event: any) => any;
  onSidebarClick: (event: any) => any;
  onRootItemClick: (par1: any) => any;
  onMenuButtonClick: (par1: any, par2: any) => any;
  onTopbarUserMenuButtonClick: (par1: any) => any;
  onTopbarUserMenuClick: (par1: any) => any;

  isHorizontalMenuActive: () => any;
}

 interface State {
  activeIndex : number;
   agent : any; 
 }

export class AppTopbar extends Component<BarProps, State> {
  agentService: AgentService;

  constructor(props: BarProps) {
    super(props);
    this.state = {
      activeIndex: 0,
      agent : {},
    };

    this.agentService = new AgentService();

    setInterval(async () => {

      this.handleGetAgentBalance();

    }, 60000)

    this.handleGetAgentBalance();
  }

  
  handleGetAgentBalance = () => {
    this.agentService.agent_get_agent_info().then(s => {
      if (s.status == "success") {

        if(s.agent != null && s.agent.agent != null ){
          s.nick = s.agent.nick
          s.agent = s.agent.agent
        }

        if(s.benefit != null && s.benefit.percent != null ){
          s.slotPercent = s.benefit.percent
        }
        

        this.setState({ agent: s });
      }
    });
  };
  //   static defaultProps = {
  //     menuActive: null,
  //     topbarUserMenuActive: false,
  //     model: null,
  //     horizontal: true,
  //     onRootMenuItemClick: null,
  //     onMenuItemClick: null,
  //     onSidebarClick: null,
  //     onMenuButtonClick: null,
  //     onTopbarUserMenuButtonClick: null,
  //     onTopbarUserMenuClick: null,
  //     isHorizontalMenuActive: null
  //   };

  //   static propTypes = {
  //     menuActive: PropTypes.bool.isRequired,
  //     topbarUserMenuActive: PropTypes.bool.isRequired,
  //     model: PropTypes.array.isRequired,
  //     horizontal: PropTypes.bool.isRequired,
  //     onRootMenuItemClick: PropTypes.func.isRequired,
  //     onMenuItemClick: PropTypes.func.isRequired,
  //     onSidebarClick: PropTypes.func.isRequired,
  //     onMenuButtonClick: PropTypes.func.isRequired,
  //     onTopbarUserMenuButtonClick: PropTypes.func.isRequired,
  //     onTopbarUserMenuClick: PropTypes.func.isRequired,
  //     isHorizontalMenuActive: PropTypes.func.isRequired
  //   };

  render() {
    let isMenuButtonActive =
      !this.props.isHorizontalMenuActive() && this.props.menuActive;
    let topbarMenuClassName = classNames("layout-profile-menu fadeInDown ", {
      "layout-profile-menu-active": this.props.topbarUserMenuActive
    });
    let menuButtonClassName = classNames("layout-menubutton ", {
      "layout-menubutton-active": isMenuButtonActive
    });

    return (
      <div className="layout-topbar">
        <button
          className={menuButtonClassName}
          onClick={e => this.props.onMenuButtonClick(e, isMenuButtonActive)}
        >
          <div className="layout-menubutton-icon" />
        </button>

        <div className="layout-topbar-grid">
          <div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
            <div className="layout-logo  p-col p-col-align-right" style={{color :'#ffb300',  paddingBottom : "0px", backgroundColor : '#000', fontSize : 18, padding : 0}}><span>{this.state.agent.agent} 알 {numTomoeny(this.state.agent.balance)}</span></div>
            <div className="layout-logo  p-col p-col-align-right" style={{color :'#ffeb3b',  paddingTop : "0px", backgroundColor : '#000', fontSize : 18, padding : 0}}><span>롤링금   {numTomoeny(this.state.agent.rolling)} ({this.state.agent.slotPercent})%</span></div>
          </div>
          {/* {this.state.agent.nick} */}

          <div className="layout-topbar-grid-column">
            <AppMenu
              items={[]}
              root={false}
              mega={false}
              className={""}
              model={this.props.model}
              horizontal={this.props.horizontal}
              menuActive={this.props.menuActive}
              isHorizontalMenuActive={this.props.isHorizontalMenuActive}
              onMenuItemClick={this.props.onMenuItemClick}
              onRootMenuItemClick={this.props.onRootMenuItemClick}
              onSidebarClick={this.props.onSidebarClick}
              parentMenuItemActive={false}
              onRootItemClick={() => {}}
            />
          </div>
          {/* 
          <div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
            <span className="layout-topbar-search">
              <span className="md-inputfield">
                <InputText type="text" />
                <label>Search</label>
                <i className="material-icons">search</i>
              </span>
            </span>
          </div> */}

          <div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
            <button
              className="p-link profile-menu-button"
              onClick={this.props.onTopbarUserMenuButtonClick}
            >
              <img src="assets/layout/images/avatar.png" alt="Profile" />
            </button>
            <ul
              className={topbarMenuClassName}
              onClick={this.props.onTopbarUserMenuClick}
            >
      

              <li role="menuitem"       onClick={() => {
                    window.location.hash = 'agentpw';
                  }}>
                <button className="p-link ripplelink">
                  <i className="material-icons">inbox</i>
                  <span>비번변경</span>
                </button>
              </li> 
          
              <li role="menuitem">
                <button
                  className="p-link ripplelink"
                  onClick={() => {
                    cookie.remove("session", { path: "/" });

                    window.location.hash = '/';
                  }}
                >
                  <i className="material-icons">cancel</i>
                  <span>Logout</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
