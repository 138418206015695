import React, { Component } from "react";

// import { Messages } from "primereact/messages";
// import { Message } from "primereact/message";
// import { NodeService } from "../service/NodeService";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { confirmAlert } from "react-confirm-alert"; // Import

import { AgentBasic } from "../share/agentdetail/agent-basic";
import { AgentBalanceLog } from "../share/agentdetail/agent-balance-log";

import { AgentBalance } from "../share/agentdetail/agnet-balance";
import { AgentBenefit } from "../share/agentdetail/agent-benefit";

// import { UserCalculate } from "../share/userdetail/user-calculate";

import { AgentService } from "../service/agent.service";
import { BalanceService } from "../service/balance.service";
import { UserService } from "../service/user.service";


import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converAgentStatus
} from "../utility/help";

interface Props {
    userID: string;
}

interface State {
  detail: any;
  
  lockeds: any[];
  checkboxValue: any[];
  eventText: any;
  eventType: any;
  giveBalance: any;
  changePW: any;

}

export class UserDetail extends Component<Props, State> {
  userService = new UserService();
  balanceService = new BalanceService();

       
  gameName = {
    "lockedslot" : "슬롯",
    "lockedcasino" : "카지노",
    "lockedminngame" : "미니게임"
  }
  constructor(props: Props) {
    super(props);
    this.state = {
      changePW : '',
      detail: {},
      checkboxValue: [],
      lockeds: [],
      
      eventType: [
        { label: "지급", value: "deposit" },
        { label: "회수", value: "withdraw" },
      ],
      eventText: "deposit",
      giveBalance: 0,
    };


    this.handleUser()
    // this.handlePageChange = this.handlePageChange.bind(this);
  }

  handleUser = async()  =>{
    

    if(process.env.REACT_APP_API_USER_LOCKD_SLOT === 'Y' || process.env.REACT_APP_API_USER_LOCKD_CASINO === 'Y'){
      const detail = await this.userService.get_game_locked(this.props.userID)
      if(detail.status === 'success'){
        this.setState({lockeds : detail.locked})
      }
    }
    

    if(process.env.REACT_APP_API_USER_CHANGE_PW === 'Y'){
      const detail = await this.userService.agent_get_user_detail(this.props.userID)
      if(detail.status === 'success'){
        this.setState({detail : detail.user})
      }
    }

  }

  componentDidMount() {
    
  }


  handleChangePW() {
    confirmAlert({
      title: "비밀번호",
      message: "해당 비밀번호를 변경하시겠습니까??.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.userService
              .agent_change_user_pw(this.props.userID, this.state.changePW)
              .then((s: any) => {
                console.log(s);
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "지급",
                    message: "보유중인 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "지급",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }
  

  handleDoDeposit() {
    confirmAlert({
      title: "지급",
      message: "해당 벨런스를 유저에게 지급하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_deposit_to_user(this.state.giveBalance, this.props.userID)
              .then((s: any) => {
                console.log(s);
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "지급",
                    message: "보유중인 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "지급",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  handleDoWithdraw() {
    confirmAlert({
      title: "회수",
      message: "해당 벨런스를 유저에게 회수하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_withdraw_to_user(this.state.giveBalance, this.props.userID)
              .then((s: any) => {
                if (s.status === "success") {
                  confirmAlert({
                    title: "회수",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "회수",
                    message: "유저 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "회수",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  


  handleLockedGame() {
    confirmAlert({
      title: "카지노 or 슬롯",
      message: "사용 또는 미사용으로 변경하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.userService
              .set_game_locked({lockeds  : this.state.lockeds, userId : this.props.userID })
              .then((s: any) => {
                console.log(s);
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                        //   this.handlePageChange(
                        //     1,
                        //     this.state.findText,
                        //     this.state.searchText
                        //   );
                        },
                      },
                    ],
                  });
                } 
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  render() {
    


    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12 p-md-3">
              <div className="card card-w-title">
                <div className="card-title">유저 지급</div>
                <div className="p-grid">

                  <div className="p-col-12 p-md-5">
                    <label htmlFor="input">아이디</label>
                  </div>
                  <div className="p-col-12 p-md-7">
                    <p style={{ textAlign: "right", marginBottom: "0px" }}>
                      {this.props.userID}
                    </p>
                  </div>
                  {
                   process.env.REACT_APP_API_USER_CHANGE_PW === 'Y' && (
                     <>
                      <div className="p-col-12 p-md-5">
                        <label htmlFor="input">비번</label>
                      </div>
                      <div className="p-col-12 p-md-7">
                        <p style={{ textAlign: "right", marginBottom: "0px" }}>
                          {this.state.detail.pw}
                        </p>
                      </div>
                     </>
                   ) 
                  }
                 


                </div>
              </div>
            </div>


            <div className="p-col-12 p-md-9">

                <div className="card card-w-title">
                  <div className="p-fluid p-grid p-formgrid">
                    <div className="p-field p-col-12 p-lg-3">
                      <Dropdown
                             options={this.state.eventType}
                             value={this.state.eventText}
                             onChange={(event: any) =>
                             this.setState({
                                 eventText: event.value,
                             })
                             }
                             style={{ width: "12em" }}

                         />
                        </div>
                      <div className="p-field p-col-12 p-md-4">

                             <InputText
                                id="username" 
                                value={this.state.giveBalance}
                                onChange={(event: any) => {
                                try {
                                    if (
                                    isNaN(
                                        Number(event.target.value.split(",").join(""))
                                    ) === false
                                    ) {
                                    this.setState({
                                        giveBalance: Number(event.target.value),
                                    });
                                    }
                                } catch (ex) {}
                                }}
                            />
                            <label htmlFor="username">지급금액</label>

                        </div>
                        <div className="p-field p-col-12 p-lg-3">
                            <Button
                            label="확인"
                            onClick={() => {
                                console.log(this.state.eventText);
                                if (this.state.eventText === "deposit") {
                                this.handleDoDeposit();
                                } else {
                                this.handleDoWithdraw();
                                }
                            }}
                            />
                        </div>
                        </div>
                    </div>
                    { 
                      process.env.REACT_APP_API_USER_CHANGE_PW === 'Y' && (
                        <div className="card card-w-title">
                          <div className="p-fluid p-grid p-formgrid">
                            <div className="p-field p-col-12 p-lg-3">비밀변호 변경</div>
                              <div className="p-field p-col-12 p-md-4">

                                    <InputText
                                        id="username" 
                                        value={this.state.changePW}
                                        onChange={(event: any) => {
                                          this.setState({changePW: event.target.value});
                                        }}
                                    />
                                    <label htmlFor="username">비밀변호</label>

                                </div>
                                <div className="p-field p-col-12 p-lg-3">
                                    <Button
                                    label="확인"
                                    onClick={() => {
                                        this.handleChangePW();
                                    }}
                                    />
                                </div>
                                </div>
                            </div>
                      )
                    }

                        { 
                          (process.env.REACT_APP_API_USER_LOCKD_CASINO === 'Y' || process.env.REACT_APP_API_USER_LOCKD_SLOT === 'Y' ) && (
                            <div className="card card-w-title">{
                              Object.values(this.state.lockeds).map((locked : any) => {
                                return (
                                  <div className="p-fluid p-grid p-formgrid">
                                  <div className="p-field p-col-12 p-lg-3">{this.gameName[locked.lockedType]}</div>
                                    <div className="p-field p-col-12 p-md-9">

                                    <Dropdown
                                      options={[
                                        { label: "미사용", value: "y" },
                                        { label: "사용", value: "n" },
                                      ]}
                                      value={locked.isLocked}
                                      onChange={(event: any) => {
                                        locked.isLocked = event.value
                                        this.setState({lockeds  :  this.state.lockeds})
                                      }}
                                      style={{ width: "12em" }}
                                    />
                                  </div>
                             
                                  </div>)})
                              }
                              <div className="p-fluid p-grid p-formgrid">
                                <div className="p-field p-col-12 p-lg-12">
                                    <Button
                                    label="적용"
                                    onClick={() => {
                                        this.handleLockedGame();
                                    }}
                                    />
                                    </div>
                                </div>
                            </div>
                          )
                        }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
