import React, { Component } from "react";
import { Message } from "primereact/message";
import { IconFont } from "react-contexify";

const { format } = require("number-currency-format");

let LogText  : any = {
  minibet: "미니게임배팅",
  miniwin: "미니게임당첨",
  livebet: "라이브배팅",
  livewin: "라이브당첨",
  sportbet: "스포츠배팅",
  sportwin: "스포츠당첨",
  bonus: "충전보너스",
  sportlose: "스포츠낙점",
  miniLose: "미니게임낙점",
  deposit: "충전",
  withdraw: "환전",
  friend: "친구추천",
  withdrawcancel: "환전취소",
};
let AgentLogText : any  = {
  admin_do_deposit: "관리자지급",
  admin_do_user_withdraw: "유저 => 관리자",
  admin_do_user_deposit: "관리자 => 유저",
  admin_do_withdraw: "관리자화수",
  exchange_to_cancel: "환전취소",
  

  minibet: "미니게임배팅",
  miniwin: "미니게임당첨",
  livebet: "라이브배팅",
  livewin: "라이브당첨",
  sportbet: "스포츠배팅",
  sportwin: "스포츠당첨",
  bonus: "충전보너스",
  sportlose: "스포츠낙점",
  miniLose: "미니게임낙점",
  deposit: "충전",
  withdraw: "환전",
  friend: "친구추천",
  withdrawcancel: "환전취소",
};

let BalanceText  : any = {
  balance: "벨런스",
  point: "포인트",
  rolling: "롤링",
  losing: "루징",
};

let AgentBenefitLogText  : any = {
  balance_losing: "루징",

  agent_sportwserwin_rolling: "스포츠 당첨 롤링",
  agent_sportuserlose_rolling: "스포츠 낙점 롤링",

  agent_miniwin_rolling: "미니게임 당첨 롤링",
  agent_minilose_rolling: "미니게임 낙점 롤링",

  agent_livewin_rolling: "라이브 당첨 롤링",
  agent_livelose_rolling: "라이브 낙점 롤링",

  agent_casino_rolling: "카지노 롤링",
  agent_slot_rolling: "슬롯 롤링",

  agent_fx_win: "FX WIN",
  agent_fx_bet: "FX BET",
  agent_fx_btc_win: "BTC WIN",
  agent_fx_btc_bet: "BTC BET",

};

let GameType : any = {
  sport: "스포츠",

  sport_special: "스페셜",
  sport_live: "스포츠 라이브",

  pwball: "파워볼",
  pwladder: "파워볼 사다리",

  kenoladder: "키노 사다리",
  speedkeno: "스피드 키노",
  vmsoccer: "가상축구",
  vmdog: "가상 개경주",
};

export const ConvertGameText = (text : any) => {
  if (GameType[text] != undefined) {
    return GameType[text];
  }
  return "미정의";
};

export const convertLogText = (text : any) => {
  if (LogText[text] != undefined) {
    return LogText[text];
  }
  return text;
};

export const convertAgentLogText = (text : any) => {
  if (AgentLogText[text] != undefined) {
    return AgentLogText[text];
  }
  return text;
};

export const convertAgentBalanceText = (text: any) => {
  if (BalanceText[text] != undefined) {
    return BalanceText[text];
  }
  return text;
};

export const convertAgentBenefitBalanceText = (text: any) => {
  if (AgentBenefitLogText[text] != undefined) {
    return AgentBenefitLogText[text];
  }
  return text;
};

export const numTomoeny = (moeny : any ) => {
  return format(moeny, { decimalsDigits: 0, decimalSeparator: "" });
};

export const converBalanceStatus = (status : any ) => {
  if (status == "wait")
    return <div style={{ color: "#3a86c8", font: "bold" }}>대기</div>;
  if (status == "reg")
    return <div style={{ color: "#3a86c8", font: "bold" }}>신청</div>;
  if (status == "cansel")
    return <div style={{ color: "#ff0000", font: "bold" }}>취소</div>;
  if (status == "already")
    return <div style={{ color: "#3eb15b", font: "bold" }}>완료</div>;
  return "";
};

export const converAgentStatus = (status : any ) => {
  if (status == "wait")
    return <div className="p-message.p-message-success">대기</div>;
  if (status == "reg") return <div className="p-message-info">신청</div>;
  if (status == "cansel") return <div className="p-message-error">삭제</div>;
  if (status == "used") return <div className="p-message-success">사용중</div>;
  return status;
};

export const converBetStatus = (status : any ) => {
  if (status == "cancel") return <Message severity="warn" text="취소" />;
  if (status == "reg") return <Message severity="info" text="대기" />;
  if (status == "lose") return <Message severity="error" text="낙점" />;
  if (status == "win") return <Message severity="success" text="당첨" />;
  return <Message severity="warn" text="미정의 " />;
};

export const getDepositColor = () => {
  return "red";
};

export const getWithdrawColor = () => {
  return "blue";
};

export const balanceColor = (balance: number) => {
  if (balance > 0) {
    return "red";
  }

  return "blue";
};

export const getDeposiOrWithdrawColor = (balance : any ) => {
  if (balance >= 0) {
    return getDepositColor();
  }
  return getWithdrawColor();
};

export const pad2 = (n : any ) => {
  return (n < 10 ? "0" : "") + n;
};

function parseDate(str) {
  var parts = str.split(" ");
  var dateparts = parts[0].split("-");
  var timeparts = (parts[1] || "").split(":");
  var year = +dateparts[0];
  var month = +dateparts[1];
  var day = +dateparts[2];
  var hours = timeparts[0] ? +timeparts[0] : 0;
  var minutes = timeparts[1] ? +timeparts[1] : 0;
  var seconds = timeparts[2] ? +timeparts[2] : 0;
  return {
    year : year,
    month : month,
    day : day,
    hours : hours,
    minutes : minutes,
  };
}

export const convertDateYear = (date1 : any ) => {
  if (date1 === null || date1 === "" || date1 === undefined) {
    return "-";
  }

  let date = parseDate(new Date(date1).toLocaleString('sv-SE'));

  return (
    pad2(date.year) + 
    "-" +
    pad2(date.month) +
    "-" +
    pad2(date.day) 
  );

  //return date;
};

export const convertDate = (date1 : any ) => {
  if (date1 === null || date1 === "" || date1 === undefined) {
    return "-";
  }

  let date = new Date(date1);

  return (
    pad2(date.getMonth() + 1) +
    "-" +
    pad2(date.getDate()) +
    " " +
    pad2(date.getHours()) +
    ":" +
    pad2(date.getMinutes()) +
    ":" +
    pad2(date.getSeconds())
  );

  //return date;
};

export const convertFullDate = (date1 : any ) => {
  if (date1 === null || date1 === "" || date1 === undefined) {
    return "-";
  }

  let date = new Date(date1);

  return (
    date.getFullYear() +
    "-" +
    pad2(date.getMonth() + 1) +
    "-" +
    pad2(date.getDate()) +
    " " +
    pad2(date.getHours()) +
    ":" +
    pad2(date.getMinutes()) +
    ":" +
    pad2(date.getSeconds())
  );

  //return date;
};

export const GetNow = () => {
  return new Date();
};



export const GetToday = () => {
  var today = new Date();
  return {
    begin: new Date(today.setHours(0, 0, 0, 0)),
    end: new Date(today.setHours(23, 59, 59, 999)),
  };
};

export const convertIP = (ip : any ) => {
  if (ip === null || ip === "") {
    return "-";
  }
  return ip;
};

export const convertSpecifiers = (specifiers : any ) => {
  if (specifiers == undefined) {
    return "-";
  }

  if (Object.values(specifiers).length == 0) {
    return "-";
  }
  let result = "";
  for (let val of Object.values(specifiers)) {
    result = result + "" + val;
  }
  return "-";
};

export const OpenUserDetail = (id : any ) => {
  var win = window.open(
    "/#/userDetail/" + id,
    "_blank",
    "width=1800,height=500"
  );
  win!.focus();
};

export const OpenAgentDetail = (id : any ) => {
  var win = window.open(
    "/#/agentDetail/" + id,
    "_blank",
    "width=1800,height=500"
  );
  win!.focus();
};

// export const OpenAgentDetail = id => {
//   var win = window.open("/userDetail/" + id, "_blank");
//   win!.focus();
// };


export const convertBalanceType = (agentId, targetId, target, give, status : any) => {
  if (status == "sportwin")
    return <span className="p-message-success">스포츠적중</span>;
  if (status == "sportlose")
    return <span className="p-message-success">스포츠낙점</span>;
  if (status == "fxwin") return <span className="p-message-success">FX적중</span>;
  if (status == "fxlose")
    return <span className="p-message-success">FX낙점</span>;
  if (status == "deposit")
    return '<span className="p-message-success">유저충전</span>';
  if (status == "withdraw")
    return '<span className="p-message-success">유저환전</span>';
 
  if (status == "deposit_agent")
    return `<string style="color : red">[파트너 유저지급]</string>&nbsp;&nbsp;&nbsp;<string>${target} => ${give}</string>`;
  if (status == "withdraw_agent")
    return `<string style="color : blue">[파트너 유저회수]</string>&nbsp;&nbsp;&nbsp;<string>${target} => ${give}</string>`;

    // trees = trees + `<string style="color : ${colors[Number(index)]}">${agent_tree_ko.split('|')[index]}</string>`

  if (status == "admin_do_user_deposit" )
    return `<string style="color : red">[센터 유저지급]</string>&nbsp;&nbsp;&nbsp;<string>${agentId}</string>`;

  if (status == "admin_do_user_withdraw")
    return `<string style="color : blue">[센터 유저회수]</string>&nbsp;&nbsp;&nbsp;<string> ${agentId}</string>`;
  if (status == "admin_do_deposit" )
    return `<string style="color : red">[센터 지급]</string>&nbsp;&nbsp;&nbsp;<string>${agentId}</string>`;

  if (status == "admin_do_withdraw")
  return `<string style="color : blue">[센터 회수]</string>&nbsp;&nbsp;&nbsp;<string> ${agentId}</string>`;
  if (status == "exchange_point_to_balance")
    return `<string style="color : blue">[포인트변환]</string>&nbsp;&nbsp;&nbsp;<string>${targetId} => ${agentId}</string>`;

    // return <span style={{color : 'red'}}>포인트변환</span>;
  if (status == "exchange_losing_to_balance")
    return `<string style="color : #4caf50">[루징변환]</string>&nbsp;&nbsp;&nbsp;<string>${agentId}</string>`;

    // return <span style={{color : 'red'}}>루징변환</span>;
  if (status == "exchange_rolling_to_balance")
    return `<string style="color : #ff9800">[롤링변환]</string>&nbsp;&nbsp;&nbsp;<string>${agentId}</string>`;

  if (status == "exchange_to_balance")
    return `<span  style="color : blue">총판환전</span>`;
  if (status == "exchange_to_cancel")
    return `<span  style="color : green">환전취소</span>`;
  if (status == "agent_withdraw_agent")
    return `<string style="color : blue">[파트너 알 회수]</string>&nbsp;&nbsp;&nbsp;<string>${target} => ${give}</string>`;
  if (status == "agent_deposit_agent")
    return `<string style="color : red">[파트너 알 지급]</string>&nbsp;&nbsp;&nbsp;<string>${target} => ${give}</string>`;
    
     
  // if (status == "exchange_to_cancel")
  //   return <div className="p-message-success">관리자회수</div>;
  return status;
};


export const GetTimeStemp = () => {
  return new Date().getTime();
};



export const HelpStatus = (status) => {
  if (status == "wait")
    return <div className="p-message.p-message-success">대기</div>;
  if (status == "reg") return <div className="p-message-info">신청</div>;
  if (status == "cansel") return <div className="p-message-error">삭제</div>;
  if (status == "already") return <div className="p-message-success">완료</div>;
  return status;
};


export const ConverAgentExBalanceType = (type) => {
  if (type === 'exchange_to_rolling') return '롤링 환전'
  else if (type === 'exchange_to_losing') return '루징 환전'
  else if (type === 'exchange_to_balance') return '벨런스 환전'
  else if (type === 'change_to_balance') return '벨런스 충전'
};




let colors = {
  0: '#ff5722',
  1: '#2196f3',
  2: '#ffc107',
  3: '#4caf50',
  4: '#',
}


let agents = {
  0: '',
  1: '부',
  2: '총',
  3: '매',
  4: '#',
}


export const ConvertNick = (level,agent_tree, agent_tree_ko) => {
  if (agent_tree_ko == null || agent_tree_ko === '') {
    return ''
  }
  let trees = ''
  for (var index in agent_tree_ko.split('|')) {
    if(Number(index)  < level - 1){
      continue
    }
    

    trees = trees + `<string style="color : ${colors[Number(index)]}">${agent_tree_ko.split('|')[index]}</string>`
    if (Number(index) < (agent_tree_ko.split('|').length - 2)) {
      trees = trees + '>>'
    }
  }

  return trees;
};
