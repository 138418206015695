import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import { BetMiniTable } from "../share/bet/bet-mini-table";
import { CustomPagination } from "../share/custom-pagination";
import { UserService } from "../service/user.service";

import { numTomoeny, GetToday } from "../utility/help";

interface MiniState {
  begin: any;
  end: any;
  userTableValue: any;
  findText: any;
  searchText: any;
  page: any;
  cities: any;
  maxCount: any;
  summary: any;
}

export class BetMini extends Component<any, MiniState> {
  userService: any = new UserService();
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      summary: {},
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" }
      ]
    };

    // this.handlePageChange = this.handlePageChange.bind(this);

    this.get_mini_bet_list(0);
  }

  componentDidMount() {}

  get_mini_bet_list = (page: any) => {
    this.userService
      .agent_get_mini_bet_list(
        page,
        this.state.begin.toLocaleString('sv-SE'),
        this.state.end.toLocaleString('sv-SE'),

        this.state.findText,
        this.state.searchText
      )
      .then((data : any) => {
        console.log(data.minis);
        this.setState({
          userTableValue: data.minis,
          maxCount: data.maxCount,
          summary: data.summary
        });
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px"
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_mini_bet_list(page);
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title" style={{ padding: "0px" }}>
                  <div className="card-title">미니게임 배팅정보</div>
                  <div className="p-col-12 ">
                    <div className="p-md-11">
                      <div className="p-grid form-group">

                        {/* <div className="p-field p-col-12 p-md-4">
                          <label htmlFor="basic">Basic</label>
                          <Calendar
                            id="basic" 
                            showIcon={true}
                            showWeek={false}
                            onChange={e => this.setState({ begin: e.value})}
                          />
                        </div> */}
                        <div className="p-col-12 p-md-2">
                          <Calendar
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={this.state.begin}
                            showWeek={false}
                            onChange={e => this.setState({ begin: e.value})}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <Calendar
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={this.state.end}
                            showWeek={false}
                            onChange={e => this.setState({ end: e.value })}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <Dropdown
                            options={this.state.cities}
                            value={this.state.findText}
                            onChange={event =>
                              this.setState({ findText: event.value })
                            }
                            autoWidth={true}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <InputText
                              onChange={(event: any) => {
                                this.setState({
                                  searchText: event.target.value
                                });
                              }}
                            />
                          </span>
                        </div>
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <Button
                              label="검색"
                              onClick={() => {
                                this.handlePageChange(0);
                              }}
                            />
                          </span>
                        </div>
                        <div className="p-col-12 p-md-2">
                          <DataTable
                            style={{ padding: "0px" }}
                            value={this.state.summary}
                            paginatorPosition="both"
                            rows={20}
                          >
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={<Message severity="error" text="BET" />}
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>{numTomoeny(rowData.totalBet)}</span>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={<Message severity="info" text="WIN" />}
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>{numTomoeny(rowData.totalWin)}</span>
                                  </div>
                                );
                              }}
                            />
                          </DataTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12">
              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                <BetMiniTable bets={this.state.userTableValue}></BetMiniTable>

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
