
// import React, { Component, AnchorHTMLAttributes } from "react";

// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";

// import { OrganizationChart } from "primereact/organizationchart";
// import { Message } from "primereact/message";
// import { DataTable } from "primereact/datatable";
// import { InputText } from "primereact/inputtext";
// import { Column } from "primereact/column";
// import { Button } from "primereact/button";
// import { Dropdown } from "primereact/dropdown";
// import { Calendar, LocaleSettings } from "primereact/calendar";
// import { SelectButton } from "primereact/selectbutton";
// import { isMobile, isMobileOnly } from "react-device-detect";
// import { ColumnGroup } from 'primereact/columngroup';
// import { Row } from 'primereact/row';

// import { BetMatchTable } from "../share/bet/bet-match-table";
// import { CustomPagination } from "../share/custom-pagination";
// import { LogBalanceTable } from "../share/log/log-balance-table";

// import { numTomoeny, balanceColor, OpenAgentDetail, OpenAgentCal } from "../utility/help";


import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentDetail,  } from "../utility/help";
import { BalanceService } from "../service/balance.service";


interface Props {
    agentId: string;
}

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    options: any[];
    options_type: string;
    begin: any;
    end: any;

    agentInfo: any[];

    level : number

}

export class CalculateSubAgent extends Component<Props, BalanceState> {
    // adminService: AdminService = new AdminService();
    balanceService: BalanceService = new BalanceService();


    constructor(props: Props) {
        super(props);

        const today = new Date();
        const begin = new Date(
            `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
        );

        today.setMonth(today.getMonth() + 1);

        const end = new Date(
            `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
        );

        this.state = {
            level : 1,
            begin: begin,
            end: end,
            findText: "",
            searchText: "",
            selectItem: {},

            options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

            options_type: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
        };

        this.handlegetSubAgentCalculate(begin, end);

    }

    handlegetSubAgentCalculate(begin: any, end: any) {
        this.balanceService.admin_get_sub_agent(this.props.agentId, begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE')).then((data) => {
            if (data.status == "success") {

                let agents = JSON.parse(JSON.stringify(data.agentCombine)).sort(function (a, b) { return b.level - a.level; });


                for (let info of agents) {
                    let losingPersistent = 0

                    for (let tree of info.agent_tree.split('|').reverse()) {
                        if (tree == null || tree == '') {
                            continue;
                        }
                        let main = data.agentCombine.find(s => s.agent == tree)
                        if (main == null) {
                            continue;
                        }
                        if (main.totalLosingBalance == null) {
                            main.totalLosingBalance = 0
                        }
                        main.totalLosingBalance += ((info.totalRealSlotBet - info.totalRealSlotWin)) * (main.benefitLosing - losingPersistent)



                        losingPersistent = main.benefitLosing

                      
                    }
                }

                // this.setState({ agentInfo: data.agentCombine , level : data.level});

                const combin = Object.values(data.agentCombine)
                combin.sort(function (a: any, b: any) {
                    if (a.agent_tree < b.agent_tree) return -1;
                    if (b.agent_tree < a.agent_tree) return 1;
                    return 0;
                })


                this.setState({ agentInfo: combin, level : data.level });

            }
        });
    }

    componentDidMount() {
    }

    render() {

        const rowClass = (data) => {

            if (data.level === 1) return { 'row-level1': data.level === 1 }
            if (data.level === 2) return { 'row-level2': data.level === 2 }
            if (data.level === 3) return { 'row-level3': data.level === 3 }


            return { 'row-level4': data.level === 4 }
        }


        // let headerGroup = (
        //     <ColumnGroup>
        //         {/* <Row>
        //             <Column header="Product" rowSpan={4} />
        //             <Column header="Sale Rate" colSpan={4} />
        //         </Row> */}
        //         <Row>
        //             <Column header="아이디" colSpan={4} />
        //             <Column header="Sales" colSpan={2} />
        //             <Column header="Profits" colSpan={2} />
        //         </Row>
        //         <Row>
        //             <Column header="Last Year" sortable field="lastYearSale" />
        //             <Column header="This Year" sortable field="thisYearSale" />
        //             <Column header="Last Year" sortable field="lastYearProfit" />
        //             <Column header="This Year" sortable field="thisYearProfit" />
        //         </Row>
        //     </ColumnGroup>
        // );
        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title"> {this.props.agentId}</div>

                                <div className="p-col-12 ">
                                    <div className="p-grid form-group">

                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.begin}
                                                onChange={(e) => this.setState({ begin: e.value })}
                                                showWeek={false}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.end}
                                                onChange={(e) => this.setState({ end: e.value })}
                                                showWeek={false}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetSubAgentCalculate(this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>
                                    </div>
                                    {
                                         process.env.REACT_APP_API_CALCULATE_BANLACE === 'Y'? (
                                             <div className="card-title"  style={{color: 'red'}}>정산금 = {'{'} 루징금(입금 - 출금) + 전분기 유저보유금  - 현분기 유저보유금  {'}'} * 루징% - 롤링 자동지급금</div>

                                         ) : (
                                             <div className="card-title"  style={{color: 'red'}}>루징금(BET - WIN) * 루징% - 롤링정산금 = 매장 정산금</div>
                                         )
                                    }



                                </div>
                            </div>
                             <div className="card"> 
                             <div className="p-col-12 ">

                             <DataTable
                                paginatorPosition="both"
                                rows={99999}
                                sortOrder={-1}
                                scrollable={true}
                                value={this.state.agentInfo}
                            >
                                
                                {
                                            this.state.level <= 1 && (
                                                <Column
                                                style={{textAlign: "center", borderRight: '1px solid #d8d8d8', width: "120px"}}
                                                header="에이전트"
                                                body={(rowData: any, column: any) => {
                                                    if (rowData.level === 1) return (
                                                        <>
                                                            <a >{rowData.agent}
                                                                <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                            </a>
                                                            <br />
                                                            <a >{rowData.nick}
                                                                <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                            </a>
                                                        </>
                                                    )
                                                    return <></>;
    
    
                                                }}
                                            />
                                            )
                                        }
                               
                                        {
                                            this.state.level <= 2 && (
                                            <Column
                                                style={{textAlign: "center", borderRight: '1px solid #d8d8d8', width: "120px" }}
                                                header="에이전트"
                                                body={(rowData: any, column: any) => {
                                                    if (rowData.level === 2) return (
                                                        <>
                                                            <a >{rowData.agent}
                                                                <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                            <br />
                                                            <a >{rowData.nick}
                                                                <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                            </a>
                                                        </>
                                                    )
                                                    return <></>;
                                                }}
                                            />
                                            )
                                        }
                                        
                                        {
                                            this.state.level <= 3 && (
                                            <Column
                                                style={{textAlign: "center", borderRight: '1px solid #d8d8d8', width: "120px" }}
                                                header="에이전트"
                                                body={(rowData: any, column: any) => {
                                                    if (rowData.level === 3) return (
                                                        <>
                                                            <a >{rowData.agent}
                                                                <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                            <br />
                                                            <a >{rowData.nick}
                                                                <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                            </a>
                                                        </>
                                                    )
                                                    return <></>;
                                                }}
                                            />
                                            )
                                        }
                                        {
                                            this.state.level <= 3 && (
                                                <Column
                                                    style={{textAlign: "center", borderRight: '1px solid #d8d8d8', width: "120px"}}
                                                    header="에이전트"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 4) return (
                                                            <>
                                                                <a>{rowData.agent}
                                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                                <br />
                                                                <a>{rowData.nick}
                                                                    <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                                </a>
                                                            </>
                                                        )
                                                        return <></>;
                                                    }}
                                                />
                                                )
                                        }

                                        <Column
                                            style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "180px" }}
                                            bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                            header={<div><div>입금</div> <div>출금</div></div>}
                                            body={(rowData: any, column: any) => {


                                                return <div>
                                                    <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit)}</div>
                                                    <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw)}</div>

                                                </div>;
                                            }}
                                        />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "180px" }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>수익 (루징금)</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        // const sum = (rowData.totalDeposit + rowData.totalGiveDeposit)- (rowData.totalWithdraw + rowData.totalGiveWithdraw)

                                                        const totalBalance = (rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit) - (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw)

                                                        return <div style={{ color: totalBalance > 0 ? 'red' : 'blue' }}>{numTomoeny(totalBalance)}</div>
                                                    }}
                                                />
                                   
                                                        {
                                                            process.env.REACT_APP_API_CALCULATE_BANLACE !== 'Y' && (
                                                                <Column
                                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "180px" }}
                                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                                header={<div><div>Bet</div><div>Win</div></div>}
                                                                body={(rowData: any, column: any) => {
                                                                    return <div>
                                                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalSlotWin)}</div>
                                                                    </div>;
            
                                                                }}
                                                            />
                                              
            
                                                            )
                                                        }
                                                        
                                                        {
                                                            process.env.REACT_APP_API_CALCULATE_BANLACE !== 'Y' && (
                                                            <Column
                                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "180px" }}
                                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                                header={<div><div>총회원 루징</div><div>(BET-WIN)</div> </div>}
                                                                body={(rowData: any, column: any) => {
                                                                    return <div>
                                                                        <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalSlotBet - rowData.totalSlotWin)}</div>
                                                                    </div>;
                                                                }}
                                                            />
                                                                    
                                                            )
                                                        }

                                                                
{
                                                            process.env.REACT_APP_API_CALCULATE_BANLACE === 'Y'? (
                                                                <Column
                                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "180px"}}
                                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                                header={<div><div>루징금*루징%</div></div>}
                                                                body={(rowData: any, column: any) => {
                                                                    const totalBalance = (rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit) - (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw)

                                                                    return <div>
                                                                        <div style={{ color: totalBalance > 0 ? 'red' : 'blue' }}>
                                                                            {numTomoeny((((totalBalance) * rowData.benefitLosing) / 100) )}</div>
                                                                        <div>{rowData.benefitLosing} %</div>
                                                                    </div>;
                                                                }}
                                                            />
                                                            )
                                                            : (
                                                                <Column
                                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "180px"}}
                                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                                    header={<div><div>루징금*루징%</div></div>}
                                                                    body={(rowData: any, column: any) => {
                                                                        return <div>
                                                                            <div style={{ color: rowData.totalLosingBalance > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalLosingBalance / 100)}</div>
                                                                        </div>;
                                                                    }}
                                                                />
                                                            )
                                                        }

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "180px"}}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>롤링정산금</div><div>롤링 프로테이지%</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)}</div>
                                                            <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                                        </div>;
                                                    }}
                                                />

                                                {
                                                    process.env.REACT_APP_API_CALCULATE_BANLACE !== 'Y' &&  (
                                                    //     <Column
                                                    //     style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "180px" }}
                                                    //     bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    //     header={<div>
                                                    //         <div>정산금</div>
                                                    //         <div>루징프로테이지%</div>
        
                                                    //     </div>}
                                                    //     body={(rowData: any, column: any) => {
        
                                                    //         const totalBalance = (rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit) - (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw)

                                                    //         return <div>
                                                    //             <div style={{ color: totalBalance > 0 ? 'red' : 'blue' }}>
                                                    //                 {numTomoeny((((totalBalance) / 100) * rowData.benefitLosing) - ((rowData.benefitSlotRolling / 100) * rowData.totalSlotBet))}</div>
                                                    //             <div>{rowData.benefitLosing} %</div>
                                                    //         </div>;
                                                    //     }}
                                                    // />
                                                    // ) : (
                                                        <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "180px" }}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div>
                                                            <div>정산금</div>
                                                            <div>루징프로테이지%</div>
        
                                                        </div>}
                                                        body={(rowData: any, column: any) => {
        
                                                            return <div>
                                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin - rowData.exchangeBalance > 0 ? 'red' : 'blue' }}>
                                                                    {numTomoeny((((rowData.totalSlotBet - rowData.totalSlotWin) / 100) * rowData.benefitLosing) - ((rowData.benefitSlotRolling / 100) * rowData.totalSlotBet))}</div>
                                                                <div>{rowData.benefitLosing} %</div>
                                                            </div>;
                                                        }}
                                                    />
                                                    )

                                                }



                           
                                </DataTable>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


// import React, { Component, AnchorHTMLAttributes } from "react";

// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";

// import { OrganizationChart } from "primereact/organizationchart";
// import { Message } from "primereact/message";
// import { DataTable } from "primereact/datatable";
// import { InputText } from "primereact/inputtext";
// import { Column } from "primereact/column";
// import { Button } from "primereact/button";
// import { Dropdown } from "primereact/dropdown";
// import { Calendar, LocaleSettings } from "primereact/calendar";
// import { SelectButton } from "primereact/selectbutton";
// import { ColumnGroup } from 'primereact/columngroup';
// import { Row } from 'primereact/row';

// import { BetMatchTable } from "../share/bet/bet-match-table";
// import { CustomPagination } from "../share/custom-pagination";
// import { LogBalanceTable } from "../share/log/log-balance-table";

// import { numTomoeny, balanceColor, OpenAgentDetail,  } from "../utility/help";
// import { BalanceService } from "../service/balance.service";

// interface Props {
//     agentId?: string;
// }

// interface BalanceState {
//     findText: any;
//     searchText: any;
//     select: any;
//     selectItem: any;
//     options: any[];
//     options_type: string;
//     begin: any;
//     end: any;

//     agentInfo: any[];

//     level : number
// }

// export class CalculateSubAgent extends Component<Props, BalanceState> {
//     balanceService: BalanceService = new BalanceService();

//     constructor(props: Props) {
//         super(props);

//         const today = new Date();
//         const begin = new Date(
//             `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
//         );

//         today.setMonth(today.getMonth() + 1);

//         const end = new Date(
//             `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
//         );

//         this.state = {
//             begin: begin,
//             end: end,
//             findText: "",
//             searchText: "",
//             selectItem: {},

//             options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

//             options_type: "balance",

//             select: [{ label: "선택", value: null }],

//             agentInfo: [],
//             level : 1,

//         };

//         this.handlegetSubAgentCalculate(this.props.agentId, begin, end);

//     }

//     handlegetSubAgentCalculate(agentId ,begin: any, end: any) {
//         this.balanceService.admin_get_sub_agent(agentId, begin, end).then((data) => {
//             if (data.status == "success") {
//                 this.setState({ agentInfo: data.agentCombine });
//             }
//         });
//     }

//     componentDidMount() {
//     }

//     render() {

//         const rowClass = (data) => {

//             if (data.level === 1) return { 'row-level1': data.level === 1 }
//             if (data.level === 2) return { 'row-level2': data.level === 2 }
//             if (data.level === 3) return { 'row-level3': data.level === 3 }


//             return { 'row-level4': data.level === 4 }
//         }


//         // let headerGroup = (
//         //     <ColumnGroup>
//         //         {/* <Row>
//         //             <Column header="Product" rowSpan={4} />
//         //             <Column header="Sale Rate" colSpan={4} />
//         //         </Row> */}
//         //         <Row>
//         //             <Column header="아이디" colSpan={4} />
//         //             <Column header="Sales" colSpan={2} />
//         //             <Column header="Profits" colSpan={2} />
//         //         </Row>
//         //         <Row>
//         //             <Column header="Last Year" sortable field="lastYearSale" />
//         //             <Column header="This Year" sortable field="thisYearSale" />
//         //             <Column header="Last Year" sortable field="lastYearProfit" />
//         //             <Column header="This Year" sortable field="thisYearProfit" />
//         //         </Row>
//         //     </ColumnGroup>
//         // );
//         return (
//             <div>
//                 {/* <Top></Top> */}
//                 <div className="content">
//                     <div className="p-grid">
//                         <div className="p-col-12">
//                             <div className="card card-w-title">
//                                 <div className="card-title">부본사 {this.props.agentId}</div>

//                                 <div className="p-col-12 ">
//                                     <div className="p-grid form-group">

//                                         <div className="p-col-12 p-md-2">
//                                             <Calendar
//                                                 placeholder="Begin"
//                                                 showIcon={true}
//                                                 dateFormat="yy-mm-dd"
//                                                 view="date"
//                                                 value={this.state.begin}
//                                                 onChange={(e) => this.setState({ begin: e.value })}
//                                                 showWeek={false}
//                                             />
//                                         </div>
//                                         <div className="p-col-12 p-md-2">
//                                             <Calendar
//                                                 placeholder="Begin"
//                                                 showIcon={true}
//                                                 dateFormat="yy-mm-dd"
//                                                 view="date"
//                                                 value={this.state.end}
//                                                 onChange={(e) => this.setState({ end: e.value })}
//                                                 showWeek={false}
//                                             />
//                                         </div>
//                                         <div className="p-col-12 p-md-2">
//                                             <Button
//                                                 label="검색"
//                                                 onClick={() => {
//                                                     this.handlegetSubAgentCalculate(this.props.agentId, this.state.begin, this.state.end);

//                                                 }}
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="card">
//                                 <div className="datatable-style-demo">

//                                     <DataTable
//                                         scrollable={true}
//                                         value={this.state.agentInfo}
//                                         rows={999}
//                                         sortOrder={-1}
//                                         style={{  padding: '0px'}}

//                                         rowClassName={rowClass}
//                                     // headerColumnGroup={headerGroup}
//                                     >
//                                         <Column
//                                             style={{
//                                                 textAlign: "center", borderRight: '1px solid #d8d8d8', visibility: this.state.level >= 2  ? 'hidden' :'visible ' 
//                                             }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             bodyStyle={{   visibility: this.state.level >= 2  ? 'hidden' :'visible '  }}
//                                             header="본사"
//                                             body={(rowData: any, column: any) => {
//                                                 if (rowData.level === 1) return (
//                                                     <>
//                                                          <a onClick={() => OpenAgentDetail(rowData.agent)}><span className="_partner_div">본</span>{rowData.agent}</a>
//                                                        {/* <br />
//                                                         <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick} </a> */}
//                                                     </>
//                                                 )
//                                                 return <></>;


//                                             }}
//                                         />
//                                         <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , visibility: this.state.level >= 3  ? 'hidden' :'visible ' }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             header="부본사"
//                                             bodyStyle={{   visibility: this.state.level >= 3  ? 'hidden' :'visible '  }}
//                                             body={(rowData: any, column: any) => {
//                                                 if (rowData.level === 2) return (
//                                                     <>
//                                                          <a onClick={() => OpenAgentDetail(rowData.agent)}><span className="_partner_div_2">부</span>{rowData.agent}</a>
//                                                        {/* <br />
//                                                         <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick} </a> */}
//                                                     </>
//                                                 )
//                                                 return <></>;
//                                             }}
//                                         />
//                                         <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', visibility: this.state.level >= 4  ? 'hidden' :'visible '  }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             header="총판"
//                                             bodyStyle={{   visibility: this.state.level >= 4  ? 'hidden' :'visible '  }}
//                                             body={(rowData: any, column: any) => {
//                                                 if (rowData.level === 3) return (
//                                                     <>
//                                                          <a onClick={() => OpenAgentDetail(rowData.agent)}><span className="_partner_div_3">총</span>{rowData.agent}</a>
//                                                         {/*<br />
//                                                         {/* <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick} </a> */}
//                                                     </>
//                                                 )
//                                                 return <></>;
//                                             }}
//                                         />
//                                         <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , visibility: this.state.level >= 5  ? 'hidden' :'visible ' }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             header="대리점"
//                                             bodyStyle={{   visibility: this.state.level >= 5  ? 'hidden' :'visible '  }}
//                                             body={(rowData: any, column: any) => {
//                                                 if (rowData.level === 4) return (
//                                                     <>
//                                                         <a onClick={() => OpenAgentDetail(rowData.agent)}><span className="_partner_div _partner_div_4">매</span>{rowData.agent}</a>
//                                                         {/* <br />
//                                                         <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick} </a> */}
//                                                     </>
//                                                 )
//                                                 return <></>;
//                                             }}
//                                         />

//                                         {/* <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
//                                             header="접속 유저"
//                                             body={(rowData: any, column: any) => {
//                                                 return <>
//                                                     <span style={{ color: rowData.conectCount > 0 ? 'red' : 'blue' }}>{rowData.conectCount}</span>
//                                                 </>;
//                                             }}
//                                         /> */}

//                                         <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
//                                             header={<div><div>유저 입금</div><div>총판 지급</div></div>}
//                                             body={(rowData: any, column: any) => {
//                                                 return <div>
//                                                     <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
//                                                     <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
//                                                 </div>;
//                                             }}
//                                         />


//                                         <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
//                                             header={<div><div>유저 출금</div><div>총판 회수</div></div>}
//                                             body={(rowData: any, column: any) => {
//                                                 return <div>
//                                                     <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
//                                                     <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
//                                                 </div>;
//                                             }}
//                                         />



//                                         <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
//                                             header={<div><div>유저 수익</div><div>총판 수익</div></div>}
//                                             body={(rowData: any, column: any) => {
//                                                 return <div>
//                                                     <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
//                                                     <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
//                                                 </div>;
//                                             }}
//                                         />

//                                         {/* <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
//                                             header={<div><div>유저 루징 (%)</div><div>총판 루징 (%)</div></div>}
//                                             body={(rowData: any, column: any) => {
//                                                 console.log(rowData.benefitLosing)
//                                                 console.log(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw) * rowData.benefitLosing))
//                                                 return <div>
//                                                     <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw) * rowData.benefitLosing) / 100)} ({rowData.benefitLosing})</div>
//                                                     <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw) * rowData.benefitLosing) / 100)} ({rowData.benefitLosing})</div>
//                                                 </div>;
//                                             }}
//                                         /> */}

//                                         <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
//                                             header={<div><div>Bet</div><div>Win</div></div>}
//                                             body={(rowData: any, column: any) => {
//                                                 return <div>
//                                                     <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
//                                                     <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalSlotWin)}</div>
//                                                 </div>;

//                                             }}
//                                         />

//                                         <Column
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
//                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
//                                             header={<div>총회원 루징</div>}
//                                             body={(rowData: any, column: any) => {
//                                                 return <div>
//                                                 <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalSlotBet - rowData.totalSlotWin)}</div>
//                                                 </div>;
//                                             }}
//                                         />

//                                         <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
//                                             header={<div><div>롤링</div><div>프로테이지</div></div>}
//                                             body={(rowData: any, column: any) => {
//                                                 return <div>
//                                                    <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)}</div>
//                                                 <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
//                                                 </div>;
//                                             }}
//                                         />
//                                         {/* <Column
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
//                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
//                                             header={<div><div>롤링 지급</div></div>}
//                                             body={(rowData: any, column: any) => {
//                                                 return <div>
//                                                     <div style={{ color: rowData.exchangeBalance > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.exchangeBalance)}</div>
//                                                 </div>;
//                                             }}
//                                         /> */}
//                                         <Column
//                                             style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
//                                             headerStyle={{ textAlign: "center",background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
//                                             bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
//                                             header={<div><div>정산금 (%)</div></div>}
//                                             body={(rowData: any, column: any) => {

//                                                 if(rowData.benefitSlotRolling === 0){
//                                                     return (
//                                                         <div>0 ({rowData.benefitSlotRolling}%)</div>
//                                                     )
//                                                 }

//                                                 return <div>
//                                                          <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin - rowData.exchangeBalance > 0 ? 'red' : 'blue' }}>
//                                                             {numTomoeny((((rowData.totalSlotBet - rowData.totalSlotWin) / 100) * rowData.benefitLosing) - ((rowData.benefitSlotRolling / 100) * rowData.totalSlotBet))}</div>
//                                                         <div>{rowData.benefitLosing} %</div>
                                                    
//                                                     {/* <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin - rowData.exchangeBalance> 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalSlotBet - rowData.totalSlotWin / )}</div> */}
//                                                 </div>;
//                                             }}
//                                         />
                                  
//                                     </DataTable>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }
