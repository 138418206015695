
// import React, { Component, AnchorHTMLAttributes } from "react";

// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";

// import { OrganizationChart } from "primereact/organizationchart";
// import { Message } from "primereact/message";
// import { DataTable } from "primereact/datatable";
// import { InputText } from "primereact/inputtext";
// import { Column } from "primereact/column";
// import { Button } from "primereact/button";
// import { Dropdown } from "primereact/dropdown";
// import { Calendar, LocaleSettings } from "primereact/calendar";
// import { SelectButton } from "primereact/selectbutton";
// import { isMobile, isMobileOnly } from "react-device-detect";
// import { ColumnGroup } from 'primereact/columngroup';
// import { Row } from 'primereact/row';

// import { BetMatchTable } from "../share/bet/bet-match-table";
// import { CustomPagination } from "../share/custom-pagination";
// import { LogBalanceTable } from "../share/log/log-balance-table";

// import { numTomoeny, balanceColor, OpenAgentDetail, OpenAgentCal } from "../utility/help";


import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentDetail, convertAgentBenefitBalanceText, GetToday } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { AgentService } from "../service/agent.service";


interface Props {
    agentId: string;
}

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    options: any[];
    options_type: string;
    begin: any;
    end: any;
    benefits : any;
    agentInfo: any[];

    level : number

}

export class CalculateSubAgentSingle extends Component<Props, BalanceState> {
    // adminService: AdminService = new AdminService();
    balanceService: BalanceService = new BalanceService();
    agentService: AgentService = new AgentService();


    constructor(props: Props) {
        super(props);
        let  today = GetToday()


        this.state = {
            level : 1,
            begin: today.begin,
            end: today.end,
            findText: "",
            searchText: "",
            selectItem: {},

            options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

            options_type: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
            benefits : [],
        };

        this.handlegetSubAgentCalculate(today.begin, today.end);
        this.handleGetAgentBenefit()
    }



    handleGetAgentBenefit = () => {
        this.agentService.agent_get_benefits().then(s => {
        if (s.status == "success") {
            this.setState({ benefits: s.benefits });
        }
        });
    };

    handlegetSubAgentCalculate(begin: any, end: any) {
        this.balanceService.admin_get_sub_agent(this.props.agentId, begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE')).then((data) => {
            if (data.status == "success") {

                let agents = JSON.parse(JSON.stringify(data.agentCombine)).sort(function (a, b) { return b.level - a.level; });

                let level = 5

                for (let info of agents) {
                    let losingPersistent = 0

                    console.log(info.agent_tree.split('|'))
                    for (let tree of info.agent_tree.split('|').reverse()) {
                        if (tree == null || tree == '') {
                            continue;
                        }
                        let main = data.agentCombine.find(s => s.agent == tree)
                        if (main == null) {
                            continue;
                        }
                        if (main.totalLosingBalance == null) {
                            main.totalLosingBalance = 0
                        }
                        main.totalLosingBalance += ((info.totalRealSlotBet - info.totalRealSlotWin)) * (main.benefitLosing - losingPersistent)


                        if (main.totalBalance == null) {
                            main.totalBalance = 0
                        }
                        main.totalBalance += (((info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance) - (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance)) * (main.benefitLosing - losingPersistent)) / 100


                        losingPersistent = main.benefitLosing

                        if(level  >= main.level){
                            level = main.level
                        }
                    }
                }

                // this.setState({ agentInfo: data.agentCombine , level : level});

                const combin = Object.values(data.agentCombine)
                combin.sort(function (a: any, b: any) {
                    if (a.agent_tree < b.agent_tree) return -1;
                    if (b.agent_tree < a.agent_tree) return 1;
                    return 0;
                })


                this.setState({ agentInfo: combin, level : level });
            }
        });
    }

    componentDidMount() {
    }

    render() {

        const rowClass = (data) => {

            if (data.level === 1) return { 'row-level1': data.level === 1 }
            if (data.level === 2) return { 'row-level2': data.level === 2 }
            if (data.level === 3) return { 'row-level3': data.level === 3 }


            return { 'row-level4': data.level === 4 }
        }


        // let headerGroup = (
        //     <ColumnGroup>
        //         {/* <Row>
        //             <Column header="Product" rowSpan={4} />
        //             <Column header="Sale Rate" colSpan={4} />
        //         </Row> */}
        //         <Row>
        //             <Column header="아이디" colSpan={4} />
        //             <Column header="Sales" colSpan={2} />
        //             <Column header="Profits" colSpan={2} />
        //         </Row>
        //         <Row>
        //             <Column header="Last Year" sortable field="lastYearSale" />
        //             <Column header="This Year" sortable field="thisYearSale" />
        //             <Column header="Last Year" sortable field="lastYearProfit" />
        //             <Column header="This Year" sortable field="thisYearProfit" />
        //         </Row>
        //     </ColumnGroup>
        // );
        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title"> {this.props.agentId}</div>
                                <div className="p-grid form-group">

                                <div className="p-col-8">
                                    <div className="p-grid form-group">

                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.begin}
                                                onChange={(e) => this.setState({ begin: e.value })}
                                                showWeek={false}
                                                hourFormat="24"
                                                showTime                                             />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="End"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.end}
                                                onChange={(e) => this.setState({ end: e.value })}
                                                showWeek={false}
                                                hourFormat="24"
                                                showTime
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetSubAgentCalculate(this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>
                                    </div>

                                    {
                                        process.env.REACT_APP_API_CALCULATE_BANLACE_NOTE === 'Y' && (
                                            <div className="card-title"  style={{color: 'red', fontSize: 12}}>1.정산일은 매달 01일 ~15일 = 16일 오전 / 16일~ 말일 = 01일 오전  정산되어 지급됩니다.</div>
                                        )
                                    }
                                    {
                                         process.env.REACT_APP_API_CALCULATE_BANLACE === 'Y'? (
                                             <div className="card-title"  style={{color: 'red', fontSize: 12}}>2. 정산금 = {'{'} 루징금(입금 - 출금) + 전분기 유저보유금  - 현분기 유저보유금 {'}'} * 루징% - 롤링 자동지금급</div>

                                         ) : (
                                             <div className="card-title"  style={{color: 'red'}}>루징금(BET - WIN) * 루징% - 롤링정산금 = 매장 정산금</div>
                                         )

                                    }

                                </div>

                                <div className="p-col-4">
                                    <div className="card-title">에이전트 벨런스</div>
                                    {
                                        Object.values(this.state.benefits).map((benefit : any) => {
                                        return (
                                            <div className="p-grid">
                                            <div className="p-col-12 p-md-2">
                                                <label htmlFor="input">{convertAgentBenefitBalanceText(benefit.benefit_type)}</label>
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                            <label htmlFor="input">
                                            {benefit.percent}
                                            </label>
                                            </div>


                                            </div>

                                        )
                                        })
                                    }
                                </div>


                                </div>

                            </div>
                             <div className="card">
                             <div className="p-col-12 ">

                             <DataTable
                                paginatorPosition="both"
                                rows={99999}
                                sortOrder={-1}
                                scrollable={true}
                                value={this.state.agentInfo}
                            >

                                {
                                            this.state.level <= 1 && (
                                                <Column
                                                style={{textAlign: "center", borderRight: '1px solid #d8d8d8', width: "120px" , visibility: this.state.level >= 2 ? 'hidden' :'visible '}}
                                                header="에이전트"
                                                body={(rowData: any, column: any) => {
                                                    if (rowData.level === 1) return (
                                                        <>
                                                            <a >{rowData.agent}
                                                                <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                            </a>
                                                            <br />
                                                            <a >{rowData.nick}
                                                                <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                            </a>
                                                        </>
                                                    )
                                                    return <></>;


                                                }}
                                            />
                                            )
                                        }

                                        {
                                            this.state.level <= 2 && (
                                            <Column
                                                style={{textAlign: "center", borderRight: '1px solid #d8d8d8', width: "120px" , visibility: this.state.level >= 3  ? 'hidden' :'visible '}}
                                                header="에이전트"
                                                body={(rowData: any, column: any) => {
                                                    if (rowData.level === 2) return (
                                                        <>
                                                            <a >{rowData.agent}
                                                                <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                            <br />
                                                            <a >{rowData.nick}
                                                                <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                            </a>
                                                        </>
                                                    )
                                                    return <></>;
                                                }}
                                            />
                                            )
                                        }

                                        {
                                            this.state.level <= 3 && (
                                            <Column
                                                style={{textAlign: "center", borderRight: '1px solid #d8d8d8', width: "120px" , visibility: this.state.level >= 4  ? 'hidden' :'visible '}}
                                                header="에이전트"
                                                body={(rowData: any, column: any) => {
                                                    if (rowData.level === 3) return (
                                                        <>
                                                            <a >{rowData.agent}
                                                                <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                            <br />
                                                            <a >{rowData.nick}
                                                                <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                            </a>
                                                        </>
                                                    )
                                                    return <></>;
                                                }}
                                            />
                                            )
                                        }
                                        {
                                            this.state.level <= 4 && (
                                                <Column
                                                    style={{textAlign: "center", borderRight: '1px solid #d8d8d8', width: "120px" , visibility: this.state.level >= 5  ? 'hidden' :'visible '}}
                                                    header="에이전트"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 4) return (
                                                            <>
                                                                <a>{rowData.agent}
                                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                                <br />
                                                                <a>{rowData.nick}
                                                                    <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                                </a>
                                                            </>
                                                        )
                                                        return <></>;
                                                    }}
                                                />
                                                )
                                        }



                                        <Column
                                            style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "180px" }}
                                            bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                            header={<div><div>배팅금액</div></div>}
                                            body={(rowData: any, column: any) => {
                                                return <div>
                                                    <div style={{ color: 'red' }}>{numTomoeny(rowData.totalRealSlotBet)}</div>

                                                </div>;
                                            }}
                                        />

                                        <Column
                                            style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "180px" }}
                                            bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                            header={<div><div>충전</div></div>}
                                            body={(rowData: any, column: any) => {
                                                return <div>
                                                    <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit)}</div>

                                                </div>;
                                            }}
                                        />


                                        <Column
                                            style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "180px" }}
                                            bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                            header={<div><div>환전</div></div>}
                                            body={(rowData: any, column: any) => {
                                                return <div>
                                                    <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw)}</div>

                                                </div>;
                                            }}
                                        />


                                                    <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "180px" }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>수익(루징금)</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        const sum = (rowData.totalDeposit + rowData.totalGiveDeposit+ rowData.totalAdmindeposit)- (rowData.totalWithdraw + rowData.totalGiveWithdraw+ rowData.totalAdminwithdraw)
                                                        return <div style={{ color: sum > 0 ? 'red' : 'blue' }}>{numTomoeny(sum)}</div>
                                                    }}
                                                />


                                            {
                                                process.env.REACT_APP_API_CALCULATE_BANLACE !== 'Y' ? (
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "180px" }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>Bet</div><div>Win</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalRealSlotBet)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalRealSlotWin)}</div>
                                                        </div>;

                                                    }}
                                                />
                                                   ) : (

                                                        <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "180px" }}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>배팅금액</div><div>배팅이긴</div></div>}
                                                        body={(rowData: any, column: any) => {
                                                            return <div>
                                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotWin)}</div>
                                                            </div>;
                                                        }} />
                                                    )
                                            }
                                            {
                                                process.env.REACT_APP_API_CALCULATE_BANLACE !== 'Y' && (
                                                    <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "180px" }}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>총회원 루징</div><div>BET-WIN</div></div>}
                                                        body={(rowData: any, column: any) => {
                                                            return <div>
                                                                <div style={{ color: rowData.totalRealSlotBet - rowData.totalRealSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalRealSlotBet - rowData.totalRealSlotWin)}</div>                                                        </div>;
                                                        }}
                                                    />
                                                )

                                            }


                                                {

                                                    process.env.REACT_APP_API_CALCULATE_BANLACE === 'Y'? (
                                                        <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "180px"}}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>루링금*루징%</div></div>}
                                                        body={(rowData: any, column: any) => {
                                                            const totalBalance = (rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit) - (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw)

                                                            return <div>
                                                                <div style={{ color: totalBalance > 0 ? 'red' : 'blue' }}>
                                                                    {numTomoeny(rowData.totalBalance)}</div>
                                                                <div>{rowData.benefitLosing} %</div>
                                                            </div>;
                                                        }}
                                                    />
                                                    ) : (
                                                        <Column
                                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' , width: "180px"}}
                                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                        header={<div><div>루징금*루징%</div></div>}
                                                        body={(rowData: any, column: any) => {
                                                            return <div>
                                                                <div style={{ color: rowData.totalLosingBalance > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalLosingBalance / 100)}</div>
                                                                <div>{rowData.benefitLosing} %</div>
                                                            </div>;
                                                        }} />
                                                    )
                                                }



                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "180px" }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>롤링정산금</div><div>롤링 프로테이지%</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.totalRollingBalance > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.totalRollingBalance)}</div>
                                                            <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                                        </div>;
                                                    }}
                                                />

                                                {
                                                    process.env.REACT_APP_API_CALCULATE_BANLACE !== 'Y' &&  (
                                                        <Column
                                                            style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', width: "180px" }}
                                                            bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                            header={<div>
                                                                <div>정산금</div>
                                                                <div>루징프로테이지%</div>

                                                            </div>}
                                                            body={(rowData: any, column: any) => {


                                                                return <div>
                                                                    <div style={{ color: (rowData.totalLosingBalance - rowData.totalRollingBalance) > 0 ? 'red' : 'blue' }}>
                                                                        {numTomoeny(((rowData.totalLosingBalance) / 100 - rowData.totalRollingBalance))}</div>
                                                                    <div>{rowData.benefitLosing} %</div>
                                                                </div>;
                                                            }}
                                                        />

                                                    )
                                                    }


                                </DataTable>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}