import React, { Component, Children } from "react";

import { PanelMenu } from "primereact/panelmenu";
import { TieredMenu } from "primereact/tieredmenu";
import { Menu } from "primereact/menu";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { Password } from "primereact/password";
import { InputMask } from "primereact/inputmask";
import { Spinner } from "primereact/spinner";
import { RadioButton } from "primereact/radiobutton";
import { Slider } from "primereact/slider";
import { MultiSelect } from "primereact/multiselect";
import { ListBox } from "primereact/listbox";

import { confirmAlert } from "react-confirm-alert";
import { TreeTable } from "primereact/treetable";
import { BalanceService } from "../service/balance.service";

import { OverlayPanel } from 'primereact/overlaypanel';
import { AlertYesNo } from "../share/alert-yes-no";
import { AgentService } from "../service/agent.service";
import {
  converAgentStatus,
  ConvertGameText,
  numTomoeny,
  OpenAgentDetail
} from "../utility/help";
import Popup from "reactjs-popup";


interface State {
    oldPW: any;
    newPW1: any;
    newPW2: any;
}

export class AgentPW extends Component<any, State> {
  agentService: AgentService = new AgentService();


  constructor(props: any) {
    super(props);

    this.state = {
        oldPW: '',
        newPW1: '',
        newPW2: '',
    };
  }

  componentDidMount() {

  }

  
  
  handleDoWithdraw() {
  
  }

  
  handleSave = () => {

    if (
        this.state.oldPW.length < 4
    ) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 비밀번호는 최소 4자리 이상을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    if (
        this.state.newPW1.length < 4
        
    ) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 비밀번호는 최소 4자리 이상을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    this.agentService.admin_change_to_password(this.state.oldPW, this.state.newPW1 ).then(data =>{

        if (data.status == "success") {
         confirmAlert({
           title: "에이전트",
           message: "에이전트 비밀번호를 변경하였습니다",
           buttons: [
             {
               label: "확인",
               onClick: () => {
               },
             },
           
           ],
         });
        }
        else{
            confirmAlert({
                title: "에이전트",
                message: "기존 비밀번호를 확인해주세요.",
                buttons: [
                  {
                    label: "확인",
                    onClick: () => {
                    },
                  },
              
                ],
              });
        }
    })
  };

  render() {
    // const RenderAgent = () =>{
    //   return this.state.agents??[].map(col=> {
    //     return   <Column field="agent" header="부본사"  style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8', }}
    //     bodyStyle={{  textAlign: "center", }}
    //     body={(node, column) => {
    //       if(node.level == 2) {
    //         return (
    //           <>
    //           <div style={{  padding: '10px', textAlign: "center"}} onClick={()=> OpenAgentDetail(node.agent)}>
    //             <span className="_partner_div_2">부</span>{node.agent}</div>
    //           </>
    //         )
    //       }
    //       return (<div></div>)
  
    //     }}></Column>
    //   });
    // }

    return (
      <div>
            <div className="p-md-12">


            <div className="card card-w-title">
            <div className="card-title">에이전트 비밀번호 변경</div>

            <div className="datatable-style-demo">

                <div className="p-grid form-group">
         
                <div className="p-col-12 ">
                    <span className="md-inputfield">
                    <InputText
                         value={this.state.oldPW}
                         onChange={(e: any) => this.setState({ oldPW: e.target.value })}
                    />
                    <label>기존비밀번호</label>
                    </span>
                </div>

                <div className="p-col-12 ">
                    <span className="md-inputfield">
                    <InputText
                         value={this.state.newPW1}
                         onChange={(e: any) => this.setState({ newPW1: e.target.value })}
                    />
                    <label>신규비밀번호</label>
                    </span>
                </div>
        
                <div className="p-col-12 ">
                    <span className="md-inputfield">
                    <Button
                        label="확 인"
                        onClick={() => this.handleSave()}
                    />
                    </span>
                </div>

            </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
