import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

interface MenuState {
  activeIndex: any;
}

interface MenuProps {
  items: any;
  root: boolean;
  mega: boolean;

  className: string;
  menuActive: boolean;
  horizontal: null;
  parentMenuItemActive: boolean;

  onRootItemClick: (par1: any) => any;
  onMenuItemClick: (par1: any) => any;

  isHorizontalMenuActive: () => any;
}

class AppSubmenu extends Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = {
      activeIndex: 0
    };
  }

  onMenuItemClick(event: any, item: any, index: any) {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    if (this.props.root && this.props.onRootItemClick) {
      this.props.onRootItemClick({
        originalEvent: event,
        item: item
      });
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    if (index === this.state.activeIndex) this.setState({ activeIndex: null });
    else this.setState({ activeIndex: index });

    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick({
        originalEvent: event,
        item: item
      });
    }
  }

  onMenuItemMouseEnter(index: any) {
    if (
      this.props.root &&
      this.props.menuActive &&
      this.props.isHorizontalMenuActive()
    ) {
      this.setState({ activeIndex: index });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.parentMenuItemActive === false) {
      return {
        activeIndex: null
      };
    }

    return null;
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (
      this.props.horizontal &&
      prevProps.menuActive &&
      !this.props.menuActive
    ) {
      this.setState({ activeIndex: null });
    }
  }

  renderLinkContent(item: any) {
    let submenuIcon = item.items && (
      <i className="material-icons layout-submenu-toggler">
        keyboard_arrow_down
      </i>
    );
    let badge = item.badge && (
      <span className="menuitem-badge">{item.badge}</span>
    );

    return (
      <React.Fragment>
        <i className="material-icons"></i>
        <span className="menuitem-text">{item.label}</span>
        {submenuIcon}
        {badge}
      </React.Fragment>
    );
  }

  renderLink(item: any, i: any) {
    let content = this.renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink
          activeClassName="active-menuitem-routerlink"
          to={item.to}
          onClick={(e: any) => this.onMenuItemClick(e, item, i)}
          exact
          target={item.target}
          onMouseEnter={(e: any) => this.onMenuItemMouseEnter(i)}
          className={classNames("ripplelink", item.styleClass)}
          style={{padding: '5px'}}

        >
          {content}
        </NavLink>
      );
    } else {
      return (
        <a
          className={classNames("ripplelink", item.styleClass)}
          href={item.url}
          onClick={e => this.onMenuItemClick(e, item, i)}
          target={item.target}
          onMouseEnter={e => this.onMenuItemMouseEnter(i)}
          style={{padding: '5px'}}
        >
          {content}
        </a>
      );
    }
  }

  render() {
    var items =
      this.props.items &&
      this.props.items.map((item: any, i: any) => {
        let active = this.state.activeIndex === i;
        let styleClass = classNames(item.badgeStyleClass, {
          "active-menuitem": active
        });
        let containerClass = classNames("layout-submenu-container", {
          "layout-submenu-megamenu-container": item.mega
        });
        let submenuClass = classNames("layout-submenu", {
          "layout-megamenu": item.mega
        });

        return (
          <li className={styleClass} key={i} style={{padding : '1px'}}>
            {this.renderLink(item, i)}
            {!this.props.root && this.props.mega && (
              <span className="layout-megamenu-submenu-text">{item.label}</span>
            )}
            {item.items && (
              <div
                className={containerClass}
                style={{ padding: active ? "" : "0" }}
              >
                <AppSubmenu
                  items={item.items}
                  className={submenuClass}
                  onMenuItemClick={this.props.onMenuItemClick}
                  horizontal={this.props.horizontal}
                  menuActive={this.props.menuActive}
                  mega={item.mega}
                  parentMenuItemActive={active}
                  isHorizontalMenuActive={this.props.isHorizontalMenuActive}
                  root={false}
                  model={null}
                  onSidebarClick={() => {}}
                  onRootMenuItemClick={() => {}}
                  onRootItemClick={() => {}}

                />

                {/* root, onRootItemClick, model, onSidebarClick, onRootMenuItemClick */}
                {/* 
                <AppSubmenu
                  items={item.items}
                  className={submenuClass}
                  onMenuItemClick={this.props.onMenuItemClick}
                  horizontal={this.props.horizontal}
                  menuActive={this.props.menuActive}
                  mega={item.mega}
                  parentMenuItemActive={active}
                  isHorizontalMenuActive={this.props.isHorizontalMenuActive}
                /> */}
              </div>
            )}
          </li>
        );
      });

    return <ul className={this.props.className}>{items}</ul>;
  }
}

interface MenuProps {
  model: any;
  root: boolean;
  mega: boolean;
  className: string;
  menuActive: boolean;
  horizontal: null;

  onSidebarClick: (par1: any) => any;
  isHorizontalMenuActive: () => any;
  onMenuItemClick: (par: any) => any;
  onRootMenuItemClick: (par: any) => any;
}

export class AppMenu extends Component<MenuProps> {
  //   static defaultProps = {
  //     model: null,
  //     horizontal: null,
  //     menuActive: null,
  //     onMenuItemClick: null,
  //     onRootMenuItemClick: null,
  //     onSidebarClick: null,
  //     isHorizontalMenuActive: null
  //   };

  //   static propTypes = {
  //     model: PropTypes.array,
  //     horizontal: PropTypes.bool,
  //     menuActive: PropTypes.bool,
  //     onMenuItemClick: PropTypes.func,
  //     onRootMenuItemClick: PropTypes.func,
  //     onSidebarClick: PropTypes.func,
  //     isHorizontalMenuActive: PropTypes.func
  //   };

  render() {
    return (
      <div
        className="layout-menu-container"
        onClick={this.props.onSidebarClick}
      >
        <div className="layout-menu-wrapper">
          <AppSubmenu
            items={this.props.model}
            root={true}
            mega={false}
            className="layout-menu"
            menuActive={this.props.menuActive}
            horizontal={this.props.horizontal}
            parentMenuItemActive={true}
            onRootItemClick={this.props.onRootMenuItemClick}
            onMenuItemClick={this.props.onMenuItemClick}
            isHorizontalMenuActive={this.props.isHorizontalMenuActive}
            model={null}
            onSidebarClick={() => {}}
            onRootMenuItemClick={() => {}}
            // items: any;
            // root: boolean;
            // mega: boolean;

            // className: string;
            // menuActive: boolean;
            // horizontal: null;
            // parentMenuItemActive: boolean;
          />
        </div>
      </div>
    );
  }
}
