import React, { Component } from "react";
//import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Message } from "primereact/message";

import { UserService } from "../service/user.service";
import { BalanceTable } from "../share/blaance/balance-table";
import { AlertYesNo } from "../share/alert-yes-no";
import { CustomPagination } from "../share/custom-pagination";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  convertFullDate,
  GetToday,
} from "../utility/help";

interface WithdrawsState {
  balances: any;
  withdraws: any;
  activePage: any;
  field: any;
  begin: any;
  end: any;
  total: any;
  maxCount: number;
  searchText: any;
  findText: any;
  dropdownFind: any;
  cities: any;
  viewType: any;
  selectViewType: any;
}

export class DeporsitAndWithdrawTotal extends Component<any, WithdrawsState> {
  userService: UserService;
  constructor(props: any) {
    super(props);

    let today = GetToday();
    this.state = {
      maxCount: 1,
      activePage: 1,
      balances: 0,
      withdraws: [],
      field: "",
      begin: today.begin,
      end: today.end,
      total: [],

      searchText: "",
      findText: "",
      dropdownFind: "id",
      selectViewType: "all",
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "이름", value: "bankowner" },
        { label: "에이전트", value: "agent" },
      ],
      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "충전", value: "deposit" },
        { label: "환전", value: "withdraw" },
      ],
    };

    this.userService = new UserService();
  }

  componentDidMount() {
    this.get_deposit_and_withdraw(0, this.state.selectViewType);
  }

  handlePageChange = (page : any) => {
    this.setState({ activePage: page });
    this.get_deposit_and_withdraw(page, this.state.selectViewType);
  };
  // active={activePage}
  // take={20}
  // count={this.state.maxCount}
  // handlePageChange={this.handlePageChange}

  OnEditItem = (idx: any, name: any, value: any) => {
    if (name == "bonus" || name == "rolling") {
      return;
    }

    let withdraw = this.state.withdraws.find((s: any) => s.idx == idx);
    if (withdraw != undefined) {
      withdraw[name] = value;
      this.setState({ withdraws: this.state.withdraws });
    }
  };

  OnSelectItem = (idx: any) => {
    let element = this.state.withdraws.find((s: any) => s.idx == idx);

    element.sel = element.sel == "y" ? "n" : "y";

    this.setState({ withdraws: this.state.withdraws });
  };

  get_deposit_and_withdraw = (page: any, type: any) => {
    let begin = this.state.begin;
    let end = this.state.end;
    let searchText = this.state.searchText;
    let findText = this.state.dropdownFind;

    this.userService
      .agent_get_deposit_and_withdraw_total_balance(
        page,
        convertFullDate(begin),
        convertFullDate(end),
        searchText,
        findText,
        type
      )
      .then((data: any) => {
        console.log(data.total)
        this.setState({
          maxCount: data.maxCount,
          balances: data.balances,
          total: data.total[0],
          searchText: searchText,
          findText: findText,
        });
      });
  };

  render() {

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title" style={{ padding: "0px" }}>
                  <div className="p-col-12 ">
                    <div className="p-md-11">
                      <div className="card-title">입급/출금 완료</div>

                      <div className="p-grid form-group">
                        <div className="p-col-12 p-md-2">
                          <Calendar
                            hourFormat="24"
                            placeholder="Begin"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={this.state.begin}
                            onChange={(e) => this.setState({ begin: e.value })}
                            showWeek={false}
                            showTime
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <Calendar
                            hourFormat="24"
                            placeholder="End"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={this.state.end}
                            onChange={(e) => this.setState({ end: e.value })}
                            showWeek={false}
                            showTime 
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <Dropdown
                            options={this.state.cities}
                            value={this.state.dropdownFind}
                            onChange={(event) =>
                              this.setState({ dropdownFind: event.value })
                            }
                            autoWidth={true}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <InputText
                              onChange={(event: any) => {
                                this.setState({
                                  searchText: event.target.value,
                                });
                              }}
                            />
                            <label>검색</label>
                          </span>
                        </div>
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <Button
                              label="검색"
                              onClick={() => {
                                this.get_deposit_and_withdraw(
                                  0,
                                  this.state.selectViewType
                                );
                  
                              }}
                            />
                          </span>
                        </div>

                  
                      </div>

                      <div className="p-grid form-group" style={{padding : '20px'}}>

                        <div className="p-col-12 p-md-2">
                          <SelectButton
                            value={this.state.selectViewType}
                            options={this.state.viewType}
                            onChange={(event: any) => {
                              this.setState({ selectViewType: event.value });
                              this.get_deposit_and_withdraw(0, event.value);
                            }}
                          />
                        </div>
                  
                        <div className="p-col-12 p-md-10">

                          <div className="p-grid form-group">
                            <span className="p-float-label p-col-12 p-md-2">
                              <InputText id="inputtext1" value={numTomoeny(this.state.total.deposit)} readOnly />
                              {/* <label htmlFor="inputtext1" style={{ color: 'red' }}>충전 {this.state.total.depositCount}</label> */}
                            </span>
                            <span className="p-float-label p-col-12 p-md-2">
                              <InputText id="inputtext2" value={numTomoeny(this.state.total.withdraw)} readOnly />
                              {/* <label htmlFor="inputtext2" style={{ color: 'blue' }}>환전 {this.state.total.withdrawCount}</label> */}
                            </span>
                            <span className="p-float-label p-col-12 p-md-2"  >
                              <InputText id="inputtext3" value={numTomoeny(this.state.total.deposit - this.state.total.withdraw)} readOnly />
                              {/* <label htmlFor="inputtext3">충전-환전</label> */}
                            </span>
                          </div>

                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="p-col-12 ">
                  <CustomPagination
                    active={this.state.activePage}
                    take={20}
                    count={this.state.maxCount}
                    handlePageChange={this.handlePageChange}
                  ></CustomPagination>

                <DataTable
                    value={this.state.balances}
                    paginatorPosition="both"
                    rows={20}
                    sortOrder={-1}
                    scrollable={true}
                >
                    <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="종류"
                    body={(rowData: any, column: any) => {
                        if (rowData.logType == "deposit" || rowData.logType == "agentdeposit" || rowData.logType == "admindeposit") {
                            return <Message severity="error" text="충전" />;
                        }

                        if (rowData.logType == "withdraw" || rowData.logType == "agentwithdraw" || rowData.logType == "adminwithdraw") {
                            return <Message severity="info" text="환전" />;
                        }
                        return "미정의";
                    }}
                    />

                    <Column
                        style={{ textAlign: "center", width: "120px" }}
                        header="아이디"
                        field="id"
                    />
                    {/* <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="입금자/출금자"
                    field="bankowner"
                    /> */}

                    <Column
                        style={{ textAlign: "center", width: "100px" }}
                        header="충전/환전"
                        body={(rowData: any, column: any) => {
                            let color = getDepositColor();
                            if (rowData.logType == "withdraw" || rowData.logType == "agentwithdraw" || rowData.logType == "adminwithdraw") {
                                color = getWithdrawColor();
                            }
                            return (
                            <span style={{ color: color }}>
                                {numTomoeny(rowData.balance)}
                            </span>
                            );
                        }}
                    field="regdate"
                    />

                  <Column
                        style={{ textAlign: "center", width: "100px" }}
                        header="남은 금액"
                        body={(rowData: any, column: any) => {
                            return (
                            <span>
                                {numTomoeny(rowData.afterbalance)}
                            </span>
                            );
                        }}
                    field="afterbalance"
                    />

                    <Column
                        style={{ textAlign: "center", width: "180px" }}
                        header={"완료날짜"}
                        body={(rowData: any, column: any) => {
                            return <span>{convertDate(rowData.regdate)}</span>;
                        }}
                    />
                
                    <Column
                        style={{ textAlign: "center", width: "100px" }}
                        header={"소속"}
                        body={(rowData: any, column: any) => {
                            return <span>{rowData.agent}</span>;
                        }}
                    />
{/* 
                    <Column
                           style={{ textAlign: "center", width: "100px" }}
                           header={"소속"}
                           body={(rowData: any, column: any) => {
                               return <span>{convertDate(rowData.agent_tree_ko)}</span>;
                           }}
                    /> */}
                </DataTable>
                  {/* <BalanceTable
                    mode="deporsitAndwithdraw"
                    balances={this.state.balances}
                    OnEditItem={this.OnEditItem}
                    OnSelectItem={this.OnSelectItem}
                  ></BalanceTable> */}

                  <CustomPagination
                    active={this.state.activePage}
                    take={20}
                    count={this.state.maxCount}
                    handlePageChange={this.handlePageChange}
                  ></CustomPagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
