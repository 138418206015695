import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";

import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";

import { AgentService } from "../service/agent.service";
import { CustomPagination } from "../share/custom-pagination";

interface State {
  items: any[];
  agents: any[];
  sel_agent: string;
  notice: any;
  activePage: number;
  maxCount: number;
}

export class NoticeAgent extends Component<any, State> {
  agentService = new AgentService();

  constructor(props: any) {
    super(props);

    this.state = {
      items: [],
      agents: [{ name: "모두", value: "" }],
      sel_agent: "",
      activePage: 1,
      maxCount: 1,
      notice: {
        del: "n",
        contents: "",
        title: ""
      }
    };

    // this.handleGetMarkets = this.handleGetMarkets.bind(this);
    //    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
     this.handleGetNotice();
    // this.handleGetAgent();
  }

  handleGetNotice = () => {
    this.agentService.admin_get_notice_agent().then(data => {
      if (data.status == "success") {
        this.setState({
          items: data.notices
        });
      }
    });
  };

  // handleGetAgent = () => {
  //   this.agentService.admin_get_agent_list().then(data => {
  //     if (data.status == "success") {
  //       let agents = this.state.agents;
  //       for (let agent of data.agents) {
  //         agents.push({
  //           name: agent.agent + " / " + agent.nick,
  //           value: agent.agent
  //         });
  //       }
  //       this.setState({
  //         agents: agents
  //       });
  //     }
  //   });
  // };

  render() {
    let items = this.state.items;

    const actionTemplate = (rowData, column) => {
      return (
        <div>
          <Button
            icon="pi-md-edit"
            onClick={() => {
              this.setState({ notice: rowData });
            }}
          ></Button>
        </div>
      );
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">에이전트 공지</div>
              </div>
            </div>
            <div className="p-col-12 p-lg-4">
              <div className="card">
                <DataTable
                  value={items}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="타이틀"
                    body={(rowData: any, column: any) => {
                      return rowData.title;
                    }}
                  />

                  <Column
                    body={actionTemplate}
                    style={{ textAlign: "center", width: "8em" }}
                  />
                </DataTable>
              </div>
            </div>
            <div className="p-col-12 p-lg-8">
              <div className="card">
                <div className="p-col-12">
                  <span className="p-float-label">
                  <div dangerouslySetInnerHTML={{ __html: this.state.notice.title}} />
                  </span>
                </div>
                <div className="p-col-12">
                  <div dangerouslySetInnerHTML={{ __html: this.state.notice.contents}} />

                </div>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
