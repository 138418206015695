import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";

import * as AgentData from "../interface/agent";

import {
  OpenAgentDetail,
  numTomoeny,
  convertDate,
  converBalanceStatus,
  ConverAgentExBalanceType,
  GetToday,
  convertFullDate
} from "../utility/help";

interface AgentState {
  balances: any;
  activePage: any;
  maxCount: any;
  total : any
  begin : any
  end : any
}

export class AgentBalanceList extends Component<any, AgentState> {
  AgentService: AgentService;
  constructor(props: any) {
    super(props);
    let today  = GetToday()

    this.state = {
      balances: [],
      activePage: 1,
      maxCount: 1,

      total : [],
      begin : today.begin,
      end : today.end
    };

    //this.handleAgentBalance = this.handleAgentBalance.bind(this);

    this.AgentService = new AgentService();
  }

  componentDidMount() {
    this.handleAgentBalance(1);
  }

  handleAgentBalance = (skip : any) => {
    this.setState({ activePage: skip });
    
    this.AgentService.agent_get_agent_balance_list(this.state.begin.toLocaleString('sv-SE'), this.state.end.toLocaleString('sv-SE'), skip).then((s) => {
      this.setState({
        total : s.total ,

        maxCount: s.maxCount,
        balances: s.balance,
      });
    });
  };

  handleMoveSel = (page: any) => {
    this.handleAgentBalance(page);
  };

  render = () => {
    return (
      <div>
        <div className="content">


        <div className="card card-w-title">
          <div className="card-title">슬롯 배팅정보</div>
          <div className="p-grid form-group">
            <div className="p-col-12 p-md-2">
              <Calendar
                placeholder="Begin"
                showIcon={true}
                dateFormat="yy-mm-dd"
                value={new Date(this.state.begin)}
                showWeek={false}
                onChange={(e: any) =>
                  this.setState({ begin: e.value })
                }
                showTime

              />
            </div>
            <div className="p-col-12 p-md-2">
              <Calendar
                placeholder="End"
                showIcon={true}
                dateFormat="yy-mm-dd"
                showWeek={false}
                value={new Date(this.state.end)}
                onChange={(e: any) =>
                this.setState({ end: e.value })
                }
                showTime

              />
            </div>

            <div className="p-col-12 p-md-2">
              <span className="md-inputfield">
                <Button
                  label="검색"
                  onClick={() => {
                    this.handleMoveSel(0);
                  }}
                />
              </span>
            </div>
            <div className="p-col-12 p-md-4">
              <DataTable
                style={{ padding: "0px" }}
                value={this.state.total}
                paginatorPosition="both"
                rows={20}
              >
                <Column
                  style={{ textAlign: "center", padding: "0px" }}
                  header={<Message severity="error" text="충전" />}
                  body={(rowData: any, column: any) => {
                    return (
                      <div>
                        <span>{numTomoeny(rowData.deposit)}</span>
                      </div>
                    );
                  }}
                />
                <Column
                  style={{ textAlign: "center", padding: "0px" }}
                  header={<Message severity="info" text="환전" />}
                  body={(rowData: any, column: any) => {
                    return (
                      <div>
                        <span>{numTomoeny(rowData.withdraw)}</span>
                      </div>
                    );
                  }}
                />
                <Column
                  style={{ textAlign: "center", padding: "0px" }}
                  header={
                    <Message severity="info" text="충전 - 환전" />
                  }
                  body={(rowData: any, column: any) => {
                    return (
                      <div>
                        <span>
                          {numTomoeny(rowData.deposit - rowData.withdraw)}
                        </span>
                      </div>
                    );
                  }}
                />
              </DataTable>
            </div> 
          </div>
          </div>



          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">에이전트 충/환전/알</div>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
                <DataTable
                  value={this.state.balances}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}

                  // onSort={this.onSortChange}
                  // header={header}
                  // sortField={this.state.sortField}
                  // sortOrder={this.state.sortOrder}
                >

                <Column 
                    style={{ textAlign: "center", width: "100px" }}
                    header="ID"
                    body={(agent: any) => {
                      return (
                        <div>
                          {ConverAgentExBalanceType(agent.type)}
                        </div>
                      );
                    }}
                    field="agent"
                  />
                  <Column
                    style={{ textAlign: "center", width: "100px" }}
                    header="ID"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(agent.agent);
                          }}
                        >
                          {agent.agent}
                        </div>
                      );
                    }}
                    field="agent"
                  />

                  <Column
                    style={{ textAlign: "center", width: "100px" }}
                    header="환전금액/알"
                    body={(agent: any) => {
                      return numTomoeny(agent.balance);
                    }}
                  />

                  <Column
                    style={{ textAlign: "center", width: "100px" }}
                    header="은행"
                    body={(agent: any) => {
                      return agent.bank;
                    }}
                    // sortFunction={}
                  />

                  <Column
                    style={{ textAlign: "center", width: "100px" }}
                    header="통장번호"
                    body={(agent: any) => {
                      return agent.nick;
                    }}
                    // sortFunction={}
                  />
                  <Column
                    style={{ textAlign: "center", width: "100px" }}
                    header="예금주"
                    body={(agent: any) => {
                      return agent.bankowner;
                    }}
                    // sortFunction={}
                  />

                  <Column
                    style={{ textAlign: "center", width: "220px" }}
                    header="신청일"
                    body={(agent: any) => {
                      return convertDate(agent.regdate);
                    }}
                  />
                  <Column
                    style={{ textAlign: "center", width: "220px" }}
                    header="완료일"
                    body={(agent: any) => {
                      return convertDate(agent.setdate);
                    }}
                  />

                  <Column
                    style={{ textAlign: "center", width: "220px" }}
                    header="완료일"
                    body={(agent: any) => {
                      return converBalanceStatus(agent.status);
                    }}
                  />

                  {/* <Column body={this.actionTemplate} style={{textAlign:'center', width: '6em'}}/> */}
                </DataTable>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
